.create-text-card {
  color: #ffffff;
  background-color: #384d6d;
  width: 220px;
  height: 100px;
  text-decoration: none;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  margin: 0px 10px 20px 20px;
  border-radius: 5px;
  transition: all 250ms ease-out 0s;
}
.create-text-card:hover {
  background-color: #586d8d;
}
.create-text-card .create-text {
  width: 80%;
  border-bottom: 1px solid #263c5c;
  color: #ffffff;
  font-size: 16px;
  font-family: Roboto, sans-serif;
  font-weight: 500;
  line-height: 1.5;
  padding: 5px;
  margin: 5px auto 5px auto;
}
.project-name-warning {
  position: absolute;
  top: 90px;
  left: 50px;
  color: #d12929;
  font-size: 16px;
}
.new-project-card {
  color: #ffffff;
  background-color: #384d6d;
  width: 220px;
  height: 100px;
  text-decoration: none;
  display: flex;
  flex-direction: column;
  margin: 0px 10px 20px 20px;
  border-radius: 5px;
  transition: all 250ms ease-out 0s;
}
.new-project-card .card-top-section {
  display: flex;
}
.new-project-card .card-top-section .new-project-input {
  width: 80%;
  border: none;
  border-bottom: 1px solid #263c5c;
  background-color: #384d6d;
  color: #ffffff;
  font-size: 16px;
  font-family: Roboto, sans-serif;
  font-weight: 500;
  line-height: 1.5;
  padding: 5px;
  margin: 5px auto 5px auto;
}
.new-project-card .card-top-section .new-project-input:focus {
  outline: none;
}
.new-project-card .card-top-section .new-project-input::placeholder {
  color: white;
}
.new-project-card .card-bottom-section {
  display: flex;
  justify-content: space-between;
  margin-top: 5px;
}
.new-project-card .card-bottom-section .cancel-new-project-button {
  background-color: #384d6d;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  width: 30%;
  color: #ffffff;
  font-size: 15px;
  font-family: Roboto, sans-serif;
  font-weight: 500;
  line-height: 1.5;
  padding: 5px;
  transition: all 250ms ease-out 0s;
}
.new-project-card .card-bottom-section .cancel-new-project-button:hover {
  background-color: #2f476b;
  outline: none;
}
.new-project-card .card-bottom-section .cancel-new-project-button:focus {
  outline: none;
}
.new-project-card .card-bottom-section .new-project-button {
  background-color: #384d6d;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  width: 40%;
  color: #ffffff;
  font-size: 15px;
  font-family: Roboto, sans-serif;
  font-weight: 500;
  line-height: 1.5;
  padding: 5px;
  transition: all 250ms ease-out 0s;
}
.new-project-card .card-bottom-section .new-project-button:hover {
  background-color: #2f476b;
  outline: none;
}
.new-project-card .card-bottom-section .new-project-button:focus {
  outline: none;
}
