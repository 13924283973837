.request-field-container {
  border: 1px solid #e6ecf1;
  border-top: none;
  margin-top: -10px;
  padding-bottom: 10px;
}
.request-field-container__request-selection {
  width: 90%;
  margin: 10px auto 20px auto;
}
.request-field-container__request-selection .selected-request__value-container {
  color: #74818d;
  background-color: white;
  font-size: 16px;
  font-family: "Roboto", Content-font;
  font-weight: 400;
  line-height: 1.2;
  margin: 0px 0px 0px 0px;
  padding: 10px 0px 0px 0px;
  display: inline-block;
}
.request-field-container__request-selection .selected-request__value-container--has-value {
  margin-top: 0px !important;
}
.request-field-container__request-selection .selected-request__single-value {
  line-height: 1.2;
  color: #74818d;
}
.request-field-container__request-selection .selected-request__control {
  border: none;
  background-color: white;
  min-height: 28px;
  max-height: 25px;
}
.request-field-container__request-selection .selected-request__control--is-focused,
.request-field-container__request-selection .selected-request__control--menu-is-open {
  border: none;
  outline: none;
  box-shadow: none;
}
.request-field-container__request-selection .selected-request__option {
  text-decoration: none;
  font-size: 13px;
  font-family: "Roboto", Content-font;
  font-weight: 600;
  padding: 5px;
}
.request-field-container__request-selection .selected-request__option--is-focused {
  color: #3884ff;
}
.request-field-container__request-selection .selected-request__option--is-selected {
  color: #3884ff;
  background-color: white;
}
.request-field-container__request-selection .selected-request__dropdown-indicator svg {
  height: 0px;
  color: black;
}
.request-field-container__request-selection .selected-request__indicator-separator {
  display: none;
}
.request-field-container__request-selection .selected-request__menu {
  margin-top: 0px;
  outline: none;
}
