.add-new-page {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  font-size: 18px;
  font-weight: 600;
}
.projects-container {
  background-color: #263c5c;
  min-height: 100vh;
  padding-bottom: 45px;
}
.projects-container .projects-top-section {
  display: flex;
  justify-content: space-between;
}
.projects-container .projects-top-section__username {
  margin: 0px 0 0 60px;
  color: white;
  font-size: 32px;
  font-family: Roboto, sans-serif;
  font-weight: 500;
  line-height: 1.5;
  padding-top: 20px;
}
.projects-container .projects-top-section__sign-out {
  margin: 5px 60px 0 20px;
  padding-top: 20px;
  position: relative;
  height: 30px;
  width: 30px;
  cursor: pointer;
}
.projects-container .projects-top-section__sign-out:hover {
  opacity: 0.5;
}
.projects-container__project-boxes {
  margin-top: 60px;
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  margin-left: 30px;
  padding-bottom: 0px;
}
.projects-container__project-boxes .individual-project {
  color: #ffffff;
  background-color: #384d6d;
  width: 220px;
  height: 100px;
  text-decoration: none;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  margin: 0px 10px 20px 20px;
  border-radius: 5px;
  transition: all 250ms ease-out 0s;
}
.projects-container__project-boxes .individual-project:hover {
  background-color: #586d8d;
}
.projects-container__project-boxes .individual-project__project-title {
  width: 80%;
  border-bottom: 1px solid #263c5c;
  color: #ffffff;
  font-size: 16px;
  font-family: Roboto, sans-serif;
  font-weight: 500;
  line-height: 1.5;
  padding: 5px;
  margin: 5px auto 5px auto;
}
.projects-container__project-boxes .individual-project__project-options {
  position: absolute;
  margin-top: -60px;
  margin-left: 190px;
  width: 48px;
}
.projects-container__project-boxes .individual-project__project-options .select-project-option {
  width: 50px;
}
.projects-container__project-boxes .individual-project__project-options .select-project-option__value-container {
  color: white;
  display: inline-block;
  font-size: 16px;
  font-family: "Roboto", Content-font;
  font-weight: 400;
  line-height: 1.2;
  background-color: transparent;
  margin: 0px;
  padding: 0;
  margin-top: 0;
  cursor: pointer;
}
.projects-container__project-boxes .individual-project__project-options .select-project-option__value-container--has-value {
  margin-top: 0px;
}
.projects-container__project-boxes .individual-project__project-options .select-project-option__single-value {
  color: white;
  line-height: 1.2;
}
.projects-container__project-boxes .individual-project__project-options .select-project-option__control {
  border: none;
  background-color: transparent;
  min-height: 28px;
  max-height: 25px;
}
.projects-container__project-boxes .individual-project__project-options .select-project-option__control--is-focused,
.projects-container__project-boxes .individual-project__project-options .select-project-option__control--menu-is-open {
  border: none;
  outline: none;
  box-shadow: none;
}
.projects-container__project-boxes .individual-project__project-options .select-project-option__option {
  text-decoration: none;
  font-size: 13px;
  font-family: "Roboto", Content-font;
  font-weight: 600;
  padding: 5px;
  color: black;
}
.projects-container__project-boxes .individual-project__project-options .select-project-option__option--is-focused {
  color: #3884ff;
}
.projects-container__project-boxes .individual-project__project-options .select-project-option__option--is-selected {
  color: #3884ff;
  background-color: white;
}
.projects-container__project-boxes .individual-project__project-options .select-project-option__indicator {
  display: none;
  max-height: 0px;
  max-width: 0px;
}
.projects-container__project-boxes .individual-project__project-options .select-project-option__indicators {
  display: none;
  max-height: 0px;
  max-width: 0px;
}
.projects-container__project-boxes .individual-project__project-options .select-project-option__dropdown-indicator svg {
  display: none;
  max-height: 0px;
  max-width: 0px;
}
.projects-container__project-boxes .individual-project__project-options .select-project-option__indicator-separator {
  display: none;
  max-height: 0px;
  max-width: 0px;
}
.projects-container__project-boxes .individual-project__project-options .select-project-option__menu {
  margin-top: 0px;
  outline: none;
}
