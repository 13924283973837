.open-sidebar-button {
  position: fixed;
  top: 0px;
  left: 0px;
  background-color: white;
  padding: 10px 10px 10px 10px;
  transition: transform 0.3s ease-out;
  z-index: 1000;
}
.open-sidebar-button:focus {
  outline: none;
}
.open-sidebar-button .arrow {
  border: solid black;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 5px;
}
.turn-right {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  transition: transform 0.3s ease-out;
}
.turn-left {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
  transition: transform 0.3s ease-out;
}
.button-stays-in-place {
  transform: translate3d(0px, 0, 0);
}
.button-moves {
  background-color: #f5f7f9;
  transform: translate3d(300px, 0, 0);
}
.sidebar {
  width: 300px;
  min-height: 100vh;
  background-color: #f5f7f9;
  position: fixed;
  top: 0;
  left: 0;
  transition: transform 0.3s ease-out;
  z-index: 1000;
  box-sizing: border-box;
  margin: 0px;
  padding: 0px;
}
.hide {
  transform: translate3d(-300px, 0, 0);
}
.show {
  transform: translate3d(0vw, 0, 0);
  overflow: hidden;
}
.sidebar-header-section {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px 12px 0px 0px;
  margin-left: 20px;
  border-bottom: 1px solid #e6ecf1;
}
.sidebar-header-section__side-logo {
  width: 2rem;
  height: 2rem;
  margin-right: 0px;
}
.sidebar-header-section__side-project-title {
  color: #242a31;
  display: block;
  flex: 1 1 0%;
  margin: 30px;
  font-size: 24px;
  font-family: Content-font, Roboto, sans-serif;
  font-weight: 500;
  line-height: 1.5;
  overflow-wrap: break-word;
}
.sidebar-header-section__side-project-title:hover {
  color: #f77d05;
}
.sidebar-header-section__search-icon {
  padding: 0 5px 0 5px;
}
.sidebar-header-section__search-icon .search-icon-img {
  margin-top: 5px;
  height: 15px;
  width: 15px;
}
.sidebar-header-section__search-icon:hover {
  border-radius: 50%;
  background-color: #e3e6e9;
}
.sidebar-scrollable-section {
  height: 85vh;
  overflow-x: hidden;
  overflow-y: overlay;
}
.sidebar-scrollable-section .sidebar-page-section {
  margin: 30px 0px 0px 20px;
  padding-bottom: 30px;
  border-bottom: 1px solid #e6ecf1;
}
.sidebar-scrollable-section .sidebar-page-section__side-group-list .side-page-groups {
  display: flex;
}
.sidebar-scrollable-section .sidebar-page-section__side-group-list .side-page-groups__side-page-group-name {
  margin-right: 0px;
  display: flex;
  position: relative;
  align-items: center;
  padding: 7px 24px 7px 16px;
  text-decoration: none;
  border: none;
  border-bottom: 1px solid #a8acaf;
  color: black;
  font-size: 14px;
  font-family: Content-font, Roboto, sans-serif;
  font-weight: 600;
  line-height: 1.5;
  width: 85%;
}
.sidebar-scrollable-section .sidebar-page-section__side-group-list .side-page-groups .delete-group-button {
  display: none;
  background-color: transparent;
  border: none;
  font-size: 16px;
  color: #9daab6;
  font-weight: 600;
  cursor: pointer;
  border-bottom: 1px solid #a8acaf;
  padding-right: 25px;
}
.sidebar-scrollable-section .sidebar-page-section__side-group-list .side-page-groups .delete-group-button:hover {
  color: black;
}
.sidebar-scrollable-section .sidebar-page-section__side-group-list .side-page-groups .delete-group-button:focus {
  outline: none;
}
.sidebar-scrollable-section .sidebar-page-section__side-group-list .side-page-groups:hover .delete-group-button {
  display: block;
}
.sidebar-scrollable-section .sidebar-page-section__side-group-list .page-title-list__page-title-content {
  display: flex;
  justify-content: space-between;
}
.sidebar-scrollable-section .sidebar-page-section__side-group-list .page-title-list__page-title-content .page-title {
  margin-right: 0px;
  display: flex;
  position: relative;
  align-items: center;
  -webkit-box-align: center;
  padding: 7px 24px 7px 16px;
  color: #9daab6;
  text-decoration: none;
  font-size: 13px;
  font-family: Content-font, Roboto, sans-serif;
  font-weight: 600;
  line-height: 1.5;
  width: 85%;
  cursor: pointer;
  transition: all 250ms ease-out 0s;
}
.sidebar-scrollable-section .sidebar-page-section__side-group-list .page-title-list__page-title-content .page-title-selected {
  margin-right: 0px;
  display: flex;
  position: relative;
  align-items: center;
  background-color: #ffffff;
  -webkit-box-align: center;
  border: 1px solid black;
  border-right: none;
  padding: 7px 24px 10px 16px;
  text-decoration: none;
  border-color: #e6ecf1;
  color: #f77d05;
  font-size: 13px;
  font-family: Content-font, Roboto, sans-serif;
  font-weight: 600;
  line-height: 1.5;
  width: 85%;
  cursor: pointer;
}
.sidebar-scrollable-section .sidebar-page-section__side-group-list .page-title-list__page-title-content .delete-page-button {
  display: none;
  background-color: transparent;
  border: none;
  font-size: 13px;
  color: #9daab6;
  font-weight: 500;
  padding-right: 25px;
  cursor: pointer;
}
.sidebar-scrollable-section .sidebar-page-section__side-group-list .page-title-list__page-title-content .delete-page-button:hover {
  color: black;
}
.sidebar-scrollable-section .sidebar-page-section__side-group-list .page-title-list__page-title-content .delete-page-button:focus {
  outline: none;
}
.sidebar-scrollable-section .sidebar-page-section__side-group-list .page-title-list__page-title-content .delete-page-button-selected {
  display: none;
  background-color: #ffffff;
  font-size: 13px;
  color: #9daab6;
  font-weight: 500;
  cursor: pointer;
  border: 1px solid #e6ecf1;
  padding: 7px 25px 10px 0px;
  border-left: none;
}
.sidebar-scrollable-section .sidebar-page-section__side-group-list .page-title-list__page-title-content .delete-page-button-selected:hover {
  color: black;
}
.sidebar-scrollable-section .sidebar-page-section__side-group-list .page-title-list__page-title-content .delete-page-button-selected:focus {
  outline: none;
}
.sidebar-scrollable-section .sidebar-page-section__side-group-list .page-title-list__page-title-content:hover {
  background-color: #e3e6e9;
}
.sidebar-scrollable-section .sidebar-page-section__side-group-list .page-title-list__page-title-content:hover .delete-page-button {
  display: block;
}
.sidebar-scrollable-section .sidebar-page-section__side-group-list .page-title-list__page-title-content:hover .delete-page-button-selected {
  display: block;
}
.sidebar-scrollable-section .sidebar-bottom-section {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 30px;
  padding-bottom: 30px;
}
.sidebar-scrollable-section .sidebar-bottom-section__back-to-projects-button {
  margin: 0px 0px 0px 20px;
  color: #ffffff;
  cursor: pointer;
  display: inline-flex;
  line-height: 1em;
  background-color: #3884ff;
  border-color: transparent;
  border-radius: 60px;
  text-decoration: none;
  transition: all 250ms ease-out 0s;
  width: 100px;
  height: 30px;
  font: 400 13.3333px Arial;
  padding: 0px 10px 0px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.sidebar-scrollable-section .sidebar-bottom-section__back-to-projects-button:hover {
  background-color: #0766ff;
}
.sidebar-scrollable-section .sidebar-bottom-section__back-to-projects-button:focus {
  outline: none;
}
