.sidebar-add-new-page-group {
  margin-left: 20px;
  font-size: 14px;
  word-break: break-word;
  font-family: Roboto, sans-serif;
  font-weight: 500;
  line-height: 1.5;
  flex: 1 1 0%;
  padding: 7px 20px 7px 16px;
  display: flex;
  /*
    &:hover {
        background-color: rgb(227, 230, 233);
    }*/
}
.sidebar-add-new-page-group__new-option-select {
  width: 50%;
  margin-right: 5px;
  min-height: 0px;
}
.sidebar-add-new-page-group__new-option-select .selected-new-option {
  min-height: 0px;
}
.sidebar-add-new-page-group__new-option-select .selected-new-option__value-container {
  padding: 2px;
  background-color: #f5f7f9;
}
.sidebar-add-new-page-group__new-option-select .selected-new-option__single-value {
  color: #9daab6;
  font-size: 13px;
  margin-left: 0;
}
.sidebar-add-new-page-group__new-option-select .selected-new-option__control {
  border: none;
  outline: none;
  min-height: 0px;
}
.sidebar-add-new-page-group__new-option-select .selected-new-option__control--is-focused {
  border: none;
  outline: none;
  box-shadow: none;
}
.sidebar-add-new-page-group__new-option-select .selected-new-option__control--menu-is-open {
  border: none;
  outline: none;
}
.sidebar-add-new-page-group__new-option-select .selected-new-option__option {
  text-decoration: none;
  font-size: 10px;
  text-transform: uppercase;
  font-weight: 600;
  padding: 5px;
}
.sidebar-add-new-page-group__new-option-select .selected-new-option__option--is-focused {
  color: #3884ff;
}
.sidebar-add-new-page-group__new-option-select .selected-new-option__option--is-selected {
  color: #3884ff;
  background-color: white;
}
.sidebar-add-new-page-group__new-option-select .selected-new-option__indicators {
  background-color: #f5f7f9;
}
.sidebar-add-new-page-group__new-option-select .selected-new-option__indicator {
  background-color: #f5f7f9;
}
.sidebar-add-new-page-group__new-option-select .selected-new-option__dropdown-indicator svg {
  display: none;
  max-height: 0px;
  min-height: 0px;
}
.sidebar-add-new-page-group__new-option-select .selected-new-option__indicator-separator {
  display: none;
}
.sidebar-add-new-page-group__new-option-select .selected-new-option__menu {
  margin-top: 0px;
  outline: none;
}
.sidebar-add-new-page-group__side-name-input {
  background-color: white;
  color: #f77d05;
  font-family: "Roboto";
  font-weight: 500;
  font-size: 14px;
  width: 85%;
  line-height: 1.625;
  border: none;
  outline: none;
  padding: 0px 3px 0px 3px;
  margin-right: 3px;
}
.sidebar-add-new-page-group__side-name-input:focus {
  border: none;
}
.sidebar-add-new-page-group__side-submit-new-page {
  background-color: #26cb7c;
  border-radius: 50%;
  border: none;
  box-shadow: none;
  height: 19px;
  max-width: 9px;
  padding: 0px 4px 0px 4px;
  margin: 7px 2px 0px 0px;
}
.sidebar-add-new-page-group__side-submit-new-page .submit-logo {
  height: 9px;
  width: 9px;
}
.sidebar-add-new-page-group__side-submit-new-page:hover {
  background-color: #01924c;
}
.sidebar-add-new-page-group__side-submit-new-page:focus {
  outline: none;
}
.sidebar-add-new-page-group__side-close-new-page {
  color: #9daab6;
  padding-top: 0px;
  background-color: #ac1a1a;
  border-radius: 50%;
  height: 19px;
  min-width: 8px;
  padding: 0px 5px 0px 5px;
  margin: 7px 2px 0px 0px;
  border: none;
  margin-left: 3px;
  font-size: 10px;
}
.sidebar-add-new-page-group__side-close-new-page:hover {
  background-color: #6d1414;
}
.sidebar-add-new-page-group__side-close-new-page:focus {
  outline: none;
}
.side-add-new-page-button {
  margin-left: 20px;
  width: 238px;
  height: 32px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: relative;
  align-items: center;
  -webkit-box-align: center;
  color: #9daab6;
  border-width: 1px 0px 1px 1px;
  border-style: solid solid solid;
  border-color: transparent transparent transparent;
  border-image: initial;
  padding: 6px 24px 7px 16px;
  border-right: 0px;
  text-decoration: none;
  font-size: 14px;
}
.side-add-new-page-button:hover {
  background-color: #e3e6e9;
}
.side-add-new-page-button .plus-icon .plus-icon-img {
  margin-top: 5px;
  height: 15px;
  width: 15px;
}
