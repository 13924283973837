.param-container {
  padding: 0px;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  margin-bottom: 5px;
  height: auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.param-container__left-section {
  margin: 3px 10px 0 0;
  display: flex;
  flex-direction: column;
  padding-left: 3px;
  width: 35%;
  min-width: 150px;
}
.param-container .left-section__name-input {
  font-size: 16px;
  font-family: "Roboto", sans-serif;
  color: #74818d;
  font-weight: 500;
  margin: 0 10px 0 5px;
  padding: 0 0 0 0;
  width: 100%;
  border: none transparent;
  outline: none;
  border-bottom: black 1rem;
  line-height: 1.525;
}
.param-container .left-section__name-input:focus {
  border-bottom: blue solid 2px;
  margin-bottom: -2px;
}
.param-container .left-section__name-input:disabled {
  background-color: transparent;
}
.param-container .left-section__option-select {
  margin-top: -5px;
  margin-left: 5px;
  width: 30%;
  min-width: 75px;
  max-width: 75px;
  background-color: transparent;
}
.param-container .left-section__option-select .get-red-required-color__value-container {
  padding: 0 0 0 0;
  margin: 0 0 0 0;
}
.param-container .left-section__option-select .get-red-required-color__value-container .css-b8ldur-Input {
  margin: 0px;
  padding: 0px;
}
.param-container .left-section__option-select .get-red-required-color__value-container--has-value {
  margin-top: 2px;
}
.param-container .left-section__option-select .get-red-required-color__single-value {
  color: red;
  font-size: 10px;
  text-transform: uppercase;
  margin-left: 0;
}
.param-container .left-section__option-select .get-red-required-color__control {
  background-color: transparent;
  border: none;
  outline: none;
  min-height: 0px;
}
.param-container .left-section__option-select .get-red-required-color__control--is-focused {
  border: none;
  outline: none;
  box-shadow: none;
}
.param-container .left-section__option-select .get-red-required-color__control--menu-is-open {
  border: none;
  outline: none;
}
.param-container .left-section__option-select .get-red-required-color__option {
  text-decoration: none;
  font-size: 10px;
  text-transform: uppercase;
  font-weight: 600;
  padding: 5px;
}
.param-container .left-section__option-select .get-red-required-color__option--is-focused {
  color: #3884ff;
}
.param-container .left-section__option-select .get-red-required-color__option--is-selected {
  color: #3884ff;
  background-color: white;
}
.param-container .left-section__option-select .get-red-required-color__dropdown-indicator svg {
  display: none;
  max-height: 0px;
  min-height: 0px;
}
.param-container .left-section__option-select .get-red-required-color__indicator-separator {
  display: none;
}
.param-container .left-section__option-select .get-red-required-color__menu {
  margin: 0px;
  outline: none;
}
.param-container .left-section__option-select .get-grey-optional-color__value-container {
  padding: 0 0 0 0;
  margin: 0 0 0 0;
}
.param-container .left-section__option-select .get-grey-optional-color__value-container .css-b8ldur-Input {
  margin: 0px;
  padding: 0px;
}
.param-container .left-section__option-select .get-grey-optional-color__value-container--has-value {
  margin-top: 2px;
}
.param-container .left-section__option-select .get-grey-optional-color__single-value {
  color: #74818d;
  font-size: 10px;
  text-transform: uppercase;
  margin-left: 0;
}
.param-container .left-section__option-select .get-grey-optional-color__control {
  background-color: transparent;
  border: none;
  outline: none;
  min-height: 0px;
}
.param-container .left-section__option-select .get-grey-optional-color__control--is-focused {
  border: none;
  outline: none;
  box-shadow: none;
}
.param-container .left-section__option-select .get-grey-optional-color__control--menu-is-open {
  border: none;
  outline: none;
}
.param-container .left-section__option-select .get-grey-optional-color__option {
  text-decoration: none;
  font-size: 10px;
  text-transform: uppercase;
  font-weight: 600;
  padding: 5px;
}
.param-container .left-section__option-select .get-grey-optional-color__option--is-focused {
  color: #3884ff;
}
.param-container .left-section__option-select .get-grey-optional-color__option--is-selected {
  color: #3884ff;
  background-color: white;
}
.param-container .left-section__option-select .get-grey-optional-color__dropdown-indicator svg {
  display: none;
  max-height: 0px;
  min-height: 0px;
}
.param-container .left-section__option-select .get-grey-optional-color__indicator-separator {
  display: none;
}
.param-container .left-section__option-select .get-grey-optional-color__menu {
  margin: 0px;
  outline: none;
}
.param-container__right-section {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-left: 5px;
  width: 60%;
  min-width: 150px;
}
.param-container .right-section__type-select {
  min-width: 65px;
  max-width: 65px;
  margin-right: 30px;
}
.param-container .right-section__type-select .type-select__value-container {
  color: black;
  font-size: 85%;
  font-family: "Source Code Pro", Menlo, monospace;
  background-color: #f5f7f9;
  display: inline-block;
  line-height: 1.2;
  margin-top: 0px;
  border-radius: 3px;
  padding: 2px 6px;
}
.param-container .right-section__type-select .type-select__control {
  border: none;
  background-color: #f5f7f9;
  min-height: 28px;
}
.param-container .right-section__type-select .type-select__control--is-focused,
.param-container .right-section__type-select .type-select__control--menu-is-open {
  border: none;
  outline: none;
  box-shadow: none;
}
.param-container .right-section__type-select .type-select__option {
  text-decoration: none;
  font-size: 85%;
  font-weight: 600;
  padding: 5px;
}
.param-container .right-section__type-select .type-select__option--is-focused {
  color: #3884ff;
}
.param-container .right-section__type-select .type-select__option--is-selected {
  color: #3884ff;
  background-color: white;
}
.param-container .right-section__type-select .type-select__dropdown-indicator svg {
  height: 0px;
  color: black;
}
.param-container .right-section__type-select .type-select__indicator {
  display: none;
}
.param-container .right-section__type-select .type-select__indicator-separator {
  display: none;
}
.param-container .right-section__type-select .type-select__menu {
  margin-top: -20px;
  outline: none;
}
.param-container .right-section__describe-input {
  font-family: "Roboto";
  font-weight: 500;
  color: #74818d;
  font-size: 16px;
  width: 80%;
  line-height: 1.625;
  border: none;
  outline: none;
  padding: 0 0 0 0;
  margin: 3px 0px 0 5px;
  min-height: 40px;
  max-height: 100px;
  resize: none;
  overflow: auto;
}
.param-container .right-section__describe-input:focus {
  border: none;
}
.param-container .right-section__describe-input:disabled {
  background-color: transparent;
}
.param-container .button-to-delete {
  display: none;
  position: absolute;
  color: #74818d;
  padding-top: 0px;
  background-color: transparent;
  border: none;
  margin-right: 10px;
  right: 14.5%;
}
.param-container .button-to-delete:hover {
  color: black;
}
.param-container .button-to-delete:focus {
  outline: none;
}
.param-container:hover .button-to-delete {
  display: block;
}
