.unauthorized-container {
  height: 100vh;
  width: 100vw;
  background-color: #263c5c;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.unauthorized-container__informative-text {
  margin: 100px 0 0 0;
  color: white;
  font-size: 18px;
}
.unauthorized-container__informative-text-2 {
  margin: 0 0 20px 0;
  color: white;
  font-size: 18px;
}
.unauthorized-container__get-back-to-login {
  width: 30%;
  margin: 0 auto 50px auto;
  text-decoration: none;
  color: #ffffff;
  font-weight: 600;
  font-size: 18px;
  cursor: pointer;
  height: 30px;
  line-height: 1em;
  background-color: #263c5c;
  border-width: 1px;
  outline: none;
  padding: 0px 16px;
  border-color: transparent;
  border-radius: 60px;
  transition: all 250ms ease-out 0s;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2px 2px 2px 2px;
}
.unauthorized-container__get-back-to-login:hover {
  background-color: #1b2c44;
  color: white;
  text-decoration: none;
}
