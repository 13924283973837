.response-container {
  padding-top: 10px;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  margin-bottom: 15px;
  height: auto;
}
.response-container__top-section {
  display: flex;
  justify-content: space-around;
  width: 100%;
  padding-top: 3px;
}
.response-container .top-section__green-circle {
  margin-top: 4px;
  margin-right: 5px;
  height: 12px;
  width: 12px;
  border-radius: 50%;
  background-color: green;
}
.response-container .top-section__orange-circle {
  margin-top: 4px;
  margin-right: 5px;
  height: 12px;
  width: 12px;
  border-radius: 50%;
  background-color: orange;
  display: inline-block;
}
.response-container .top-section__red-circle {
  margin-top: 4px;
  margin-right: 5px;
  height: 12px;
  width: 12px;
  border-radius: 50%;
  background-color: red;
  display: inline-block;
}
.response-container .top-section__selectedOption {
  width: 100%;
  color: black;
  display: inline-block;
  font-size: 16px;
  font-weight: 600;
  font-family: Content-font, Roboto, sans-serif;
  line-height: 1.2;
  background-color: white;
  border-radius: 3px;
}
.response-container .top-section__button-to-delete {
  display: none;
  position: absolute;
  right: 0;
  line-height: 1;
  color: #74818d;
  background-color: white;
  border: none;
}
.response-container .top-section__button-to-delete:hover {
  color: black;
}
.response-container .top-section__button-to-delete:focus {
  outline: none;
}
.response-container__middle-section {
  display: flex;
  justify-content: space-around;
  width: 100%;
  margin-bottom: 10px;
}
.response-container .middle-section__responeExample-input {
  width: 100%;
  font-family: "Roboto";
  font-weight: 500;
  line-height: 1.625;
  border: none;
  outline: none;
  color: #74818d;
  font-size: 16px;
  padding: 0px;
  margin: 0px;
}
.response-container .middle-section__responeExample-input:focus {
  border: none;
}
.response-container .middle-section__responeExample-input:disabled {
  background-color: transparent;
}
.response-container__bottom-section {
  display: flex;
  max-width: 600px;
}
.response-container .bottom-section__codePane {
  font-family: "Roboto";
  font-weight: 500;
  line-height: 1.625;
  border: none;
  outline: none;
  color: #74818d;
  display: flex;
}
.response-container .bottom-section__codePane .code-editor {
  margin-right: 10px;
  max-width: 71.5vw;
  min-width: 71.5vw;
  background-color: #183055;
  border-radius: 5px;
  color: white;
  border: none;
  outline: none;
  font-family: "Fira code", "Fira Mono", monospace;
  font-size: 10;
}
.response-container .bottom-section__codePane .code-editor:focus {
  outline: none;
  border: none;
}
.response-container .bottom-section__clipboard {
  position: absolute;
  right: 0%;
  margin: 14px 8px 0 0;
  background-color: #183055;
  color: white;
  cursor: pointer;
  padding: 0px 5px 0px 0px;
  align-items: center;
}
.response-container .bottom-section__clipboard .copyButton {
  width: 15px;
  height: 15px;
}
.response-container .bottom-section__clipboard:hover {
  opacity: 0.5;
}
.response-container .bottom-section__clipboard:focus {
  outline: none;
}
.response-container:hover .top-section__button-to-delete {
  display: block;
}
