.loader {
  font-size: 10px;
  margin: 200px auto;
  text-indent: -9999em;
  width: 11em;
  height: 11em;
  border-radius: 50%;
  background: #263c5c;
  background: linear-gradient(to right, #263c5c, 10%, rgba(0, 0, 160, 0) 42%);
  position: relative;
  -webkit-animation: load3 1.4s infinite linear;
  animation: load3 1.4s infinite linear;
  transform: translateZ(0);
}
.loader:before {
  width: 50%;
  height: 50%;
  background: #263c5c;
  border-radius: 100% 0 0 0;
  position: absolute;
  top: 0;
  left: 0;
  content: "";
}
.loader:after {
  background: white;
  width: 75%;
  height: 75%;
  border-radius: 50%;
  content: "";
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
@-webkit-keyframes load3 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes load3 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.new-project-spinner {
  font-size: 5px;
  margin: 20px 98px;
  text-indent: -9999em;
  width: 11em;
  height: 11em;
  border-radius: 50%;
  background: #ffffff;
  background: linear-gradient(to right, #ffffff 10%, rgba(255, 255, 255, 0) 42%);
  position: relative;
  -webkit-animation: load3 1.4s infinite linear;
  animation: load3 1.4s infinite linear;
  transform: translateZ(0);
}
.new-project-spinner:before {
  width: 50%;
  height: 50%;
  background: #ffffff;
  border-radius: 100% 0 0 0;
  position: absolute;
  top: 0;
  left: 0;
  content: "";
}
.new-project-spinner:after {
  background: #263c5c;
  width: 75%;
  height: 75%;
  border-radius: 50%;
  content: "";
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
@-webkit-keyframes load3 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes load3 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.projects-delete-spinner {
  font-size: 5px;
  margin: 20px 98px;
  text-indent: -9999em;
  width: 11em;
  height: 11em;
  border-radius: 50%;
  background: #ffffff;
  background: linear-gradient(to right, #ffffff 10%, rgba(255, 255, 255, 0) 42%);
  position: relative;
  -webkit-animation: load3 1.4s infinite linear;
  animation: load3 1.4s infinite linear;
  transform: translateZ(0);
}
.projects-delete-spinner:before {
  width: 50%;
  height: 50%;
  background: #ffffff;
  border-radius: 100% 0 0 0;
  position: absolute;
  top: 0;
  left: 0;
  content: "";
}
.projects-delete-spinner:after {
  background: #263c5c;
  width: 75%;
  height: 75%;
  border-radius: 50%;
  content: "";
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
@-webkit-keyframes load3 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes load3 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.adding-elements-to-sidebar {
  font-size: 2px;
  margin: 10px auto;
  text-indent: -9999em;
  width: 11em;
  height: 11em;
  border-radius: 50%;
  background: #263c5c;
  background: linear-gradient(to right, #263c5c, 10%, rgba(0, 0, 160, 0) 42%);
  position: relative;
  -webkit-animation: load3 1.4s infinite linear;
  animation: load3 1.4s infinite linear;
  transform: translateZ(0);
}
.adding-elements-to-sidebar:before {
  width: 50%;
  height: 50%;
  background: #263c5c;
  border-radius: 100% 0 0 0;
  position: absolute;
  top: 0;
  left: 0;
  content: "";
}
.adding-elements-to-sidebar:after {
  background: white;
  width: 75%;
  height: 75%;
  border-radius: 50%;
  content: "";
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
@-webkit-keyframes load3 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes load3 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.sidebar-content-spinner {
  font-size: 7px;
  margin: 80px auto;
  text-indent: -9999em;
  width: 11em;
  height: 11em;
  border-radius: 50%;
  background: #263c5c;
  background: linear-gradient(to right, #263c5c, 10%, rgba(0, 0, 160, 0) 42%);
  position: relative;
  -webkit-animation: load3 1.4s infinite linear;
  animation: load3 1.4s infinite linear;
  transform: translateZ(0);
}
.sidebar-content-spinner:before {
  width: 50%;
  height: 50%;
  background: #263c5c;
  border-radius: 100% 0 0 0;
  position: absolute;
  top: 0;
  left: 0;
  content: "";
}
.sidebar-content-spinner:after {
  background: #f5f7f9;
  width: 75%;
  height: 75%;
  border-radius: 50%;
  content: "";
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
@-webkit-keyframes load3 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes load3 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.saving-page-spinner {
  font-size: 5px;
  position: absolute;
  top: 45px;
  right: 11.5%;
  width: 8em;
  height: 8em;
  border-radius: 50%;
  background: #263c5c;
  background: linear-gradient(to right, #263c5c, 10%, rgba(0, 0, 160, 0) 42%);
  -webkit-animation: load3 1.4s infinite linear;
  animation: load3 1.4s infinite linear;
  transform: translateZ(0);
}
.saving-page-spinner:before {
  width: 50%;
  height: 50%;
  background: #263c5c;
  border-radius: 100% 0 0 0;
  position: absolute;
  top: 0;
  left: 0;
  content: "";
}
.saving-page-spinner:after {
  background: white;
  width: 75%;
  height: 75%;
  border-radius: 50%;
  content: "";
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
@-webkit-keyframes load3 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes load3 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.error {
  position: absolute;
  left: 35%;
  top: 5%;
  color: black;
  font-size: 32px;
  font-weight: 500;
}

.create-text-card {
  color: #ffffff;
  background-color: #384d6d;
  width: 220px;
  height: 100px;
  text-decoration: none;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  margin: 0px 10px 20px 20px;
  border-radius: 5px;
  transition: all 250ms ease-out 0s;
}
.create-text-card:hover {
  background-color: #586d8d;
}
.create-text-card .create-text {
  width: 80%;
  border-bottom: 1px solid #263c5c;
  color: #ffffff;
  font-size: 16px;
  font-family: Roboto, sans-serif;
  font-weight: 500;
  line-height: 1.5;
  padding: 5px;
  margin: 5px auto 5px auto;
}
.project-name-warning {
  position: absolute;
  top: 90px;
  left: 50px;
  color: #d12929;
  font-size: 16px;
}
.new-project-card {
  color: #ffffff;
  background-color: #384d6d;
  width: 220px;
  height: 100px;
  text-decoration: none;
  display: flex;
  flex-direction: column;
  margin: 0px 10px 20px 20px;
  border-radius: 5px;
  transition: all 250ms ease-out 0s;
}
.new-project-card .card-top-section {
  display: flex;
}
.new-project-card .card-top-section .new-project-input {
  width: 80%;
  border: none;
  border-bottom: 1px solid #263c5c;
  background-color: #384d6d;
  color: #ffffff;
  font-size: 16px;
  font-family: Roboto, sans-serif;
  font-weight: 500;
  line-height: 1.5;
  padding: 5px;
  margin: 5px auto 5px auto;
}
.new-project-card .card-top-section .new-project-input:focus {
  outline: none;
}
.new-project-card .card-top-section .new-project-input::-webkit-input-placeholder {
  color: white;
}
.new-project-card .card-top-section .new-project-input:-ms-input-placeholder {
  color: white;
}
.new-project-card .card-top-section .new-project-input::-ms-input-placeholder {
  color: white;
}
.new-project-card .card-top-section .new-project-input::placeholder {
  color: white;
}
.new-project-card .card-bottom-section {
  display: flex;
  justify-content: space-between;
  margin-top: 5px;
}
.new-project-card .card-bottom-section .cancel-new-project-button {
  background-color: #384d6d;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  width: 30%;
  color: #ffffff;
  font-size: 15px;
  font-family: Roboto, sans-serif;
  font-weight: 500;
  line-height: 1.5;
  padding: 5px;
  transition: all 250ms ease-out 0s;
}
.new-project-card .card-bottom-section .cancel-new-project-button:hover {
  background-color: #2f476b;
  outline: none;
}
.new-project-card .card-bottom-section .cancel-new-project-button:focus {
  outline: none;
}
.new-project-card .card-bottom-section .new-project-button {
  background-color: #384d6d;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  width: 40%;
  color: #ffffff;
  font-size: 15px;
  font-family: Roboto, sans-serif;
  font-weight: 500;
  line-height: 1.5;
  padding: 5px;
  transition: all 250ms ease-out 0s;
}
.new-project-card .card-bottom-section .new-project-button:hover {
  background-color: #2f476b;
  outline: none;
}
.new-project-card .card-bottom-section .new-project-button:focus {
  outline: none;
}

.add-new-page {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  font-size: 18px;
  font-weight: 600;
}
.projects-container {
  background-color: #263c5c;
  min-height: 100vh;
  padding-bottom: 45px;
}
.projects-container .projects-top-section {
  display: flex;
  justify-content: space-between;
}
.projects-container .projects-top-section__username {
  margin: 0px 0 0 60px;
  color: white;
  font-size: 32px;
  font-family: Roboto, sans-serif;
  font-weight: 500;
  line-height: 1.5;
  padding-top: 20px;
}
.projects-container .projects-top-section__sign-out {
  margin: 5px 60px 0 20px;
  padding-top: 20px;
  position: relative;
  height: 30px;
  width: 30px;
  cursor: pointer;
}
.projects-container .projects-top-section__sign-out:hover {
  opacity: 0.5;
}
.projects-container__project-boxes {
  margin-top: 60px;
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  margin-left: 30px;
  padding-bottom: 0px;
}
.projects-container__project-boxes .individual-project {
  color: #ffffff;
  background-color: #384d6d;
  width: 220px;
  height: 100px;
  text-decoration: none;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  margin: 0px 10px 20px 20px;
  border-radius: 5px;
  transition: all 250ms ease-out 0s;
}
.projects-container__project-boxes .individual-project:hover {
  background-color: #586d8d;
}
.projects-container__project-boxes .individual-project__project-title {
  width: 80%;
  border-bottom: 1px solid #263c5c;
  color: #ffffff;
  font-size: 16px;
  font-family: Roboto, sans-serif;
  font-weight: 500;
  line-height: 1.5;
  padding: 5px;
  margin: 5px auto 5px auto;
}
.projects-container__project-boxes .individual-project__project-options {
  position: absolute;
  margin-top: -60px;
  margin-left: 190px;
  width: 48px;
}
.projects-container__project-boxes .individual-project__project-options .select-project-option {
  width: 50px;
}
.projects-container__project-boxes .individual-project__project-options .select-project-option__value-container {
  color: white;
  display: inline-block;
  font-size: 16px;
  font-family: "Roboto", Content-font;
  font-weight: 400;
  line-height: 1.2;
  background-color: transparent;
  margin: 0px;
  padding: 0;
  margin-top: 0;
  cursor: pointer;
}
.projects-container__project-boxes .individual-project__project-options .select-project-option__value-container--has-value {
  margin-top: 0px;
}
.projects-container__project-boxes .individual-project__project-options .select-project-option__single-value {
  color: white;
  line-height: 1.2;
}
.projects-container__project-boxes .individual-project__project-options .select-project-option__control {
  border: none;
  background-color: transparent;
  min-height: 28px;
  max-height: 25px;
}
.projects-container__project-boxes .individual-project__project-options .select-project-option__control--is-focused,
.projects-container__project-boxes .individual-project__project-options .select-project-option__control--menu-is-open {
  border: none;
  outline: none;
  box-shadow: none;
}
.projects-container__project-boxes .individual-project__project-options .select-project-option__option {
  text-decoration: none;
  font-size: 13px;
  font-family: "Roboto", Content-font;
  font-weight: 600;
  padding: 5px;
  color: black;
}
.projects-container__project-boxes .individual-project__project-options .select-project-option__option--is-focused {
  color: #3884ff;
}
.projects-container__project-boxes .individual-project__project-options .select-project-option__option--is-selected {
  color: #3884ff;
  background-color: white;
}
.projects-container__project-boxes .individual-project__project-options .select-project-option__indicator {
  display: none;
  max-height: 0px;
  max-width: 0px;
}
.projects-container__project-boxes .individual-project__project-options .select-project-option__indicators {
  display: none;
  max-height: 0px;
  max-width: 0px;
}
.projects-container__project-boxes .individual-project__project-options .select-project-option__dropdown-indicator svg {
  display: none;
  max-height: 0px;
  max-width: 0px;
}
.projects-container__project-boxes .individual-project__project-options .select-project-option__indicator-separator {
  display: none;
  max-height: 0px;
  max-width: 0px;
}
.projects-container__project-boxes .individual-project__project-options .select-project-option__menu {
  margin-top: 0px;
  outline: none;
}

.backdrop {
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 100;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.5);
}

.sidebar-add-new-page-group {
  margin-left: 20px;
  font-size: 14px;
  word-break: break-word;
  font-family: Roboto, sans-serif;
  font-weight: 500;
  line-height: 1.5;
  flex: 1 1;
  padding: 7px 20px 7px 16px;
  display: flex;
  /*
    &:hover {
        background-color: rgb(227, 230, 233);
    }*/
}
.sidebar-add-new-page-group__new-option-select {
  width: 50%;
  margin-right: 5px;
  min-height: 0px;
}
.sidebar-add-new-page-group__new-option-select .selected-new-option {
  min-height: 0px;
}
.sidebar-add-new-page-group__new-option-select .selected-new-option__value-container {
  padding: 2px;
  background-color: #f5f7f9;
}
.sidebar-add-new-page-group__new-option-select .selected-new-option__single-value {
  color: #9daab6;
  font-size: 13px;
  margin-left: 0;
}
.sidebar-add-new-page-group__new-option-select .selected-new-option__control {
  border: none;
  outline: none;
  min-height: 0px;
}
.sidebar-add-new-page-group__new-option-select .selected-new-option__control--is-focused {
  border: none;
  outline: none;
  box-shadow: none;
}
.sidebar-add-new-page-group__new-option-select .selected-new-option__control--menu-is-open {
  border: none;
  outline: none;
}
.sidebar-add-new-page-group__new-option-select .selected-new-option__option {
  text-decoration: none;
  font-size: 10px;
  text-transform: uppercase;
  font-weight: 600;
  padding: 5px;
}
.sidebar-add-new-page-group__new-option-select .selected-new-option__option--is-focused {
  color: #3884ff;
}
.sidebar-add-new-page-group__new-option-select .selected-new-option__option--is-selected {
  color: #3884ff;
  background-color: white;
}
.sidebar-add-new-page-group__new-option-select .selected-new-option__indicators {
  background-color: #f5f7f9;
}
.sidebar-add-new-page-group__new-option-select .selected-new-option__indicator {
  background-color: #f5f7f9;
}
.sidebar-add-new-page-group__new-option-select .selected-new-option__dropdown-indicator svg {
  display: none;
  max-height: 0px;
  min-height: 0px;
}
.sidebar-add-new-page-group__new-option-select .selected-new-option__indicator-separator {
  display: none;
}
.sidebar-add-new-page-group__new-option-select .selected-new-option__menu {
  margin-top: 0px;
  outline: none;
}
.sidebar-add-new-page-group__side-name-input {
  background-color: white;
  color: #f77d05;
  font-family: "Roboto";
  font-weight: 500;
  font-size: 14px;
  width: 85%;
  line-height: 1.625;
  border: none;
  outline: none;
  padding: 0px 3px 0px 3px;
  margin-right: 3px;
}
.sidebar-add-new-page-group__side-name-input:focus {
  border: none;
}
.sidebar-add-new-page-group__side-submit-new-page {
  background-color: #26cb7c;
  border-radius: 50%;
  border: none;
  box-shadow: none;
  height: 19px;
  max-width: 9px;
  padding: 0px 4px 0px 4px;
  margin: 7px 2px 0px 0px;
}
.sidebar-add-new-page-group__side-submit-new-page .submit-logo {
  height: 9px;
  width: 9px;
}
.sidebar-add-new-page-group__side-submit-new-page:hover {
  background-color: #01924c;
}
.sidebar-add-new-page-group__side-submit-new-page:focus {
  outline: none;
}
.sidebar-add-new-page-group__side-close-new-page {
  color: #9daab6;
  padding-top: 0px;
  background-color: #ac1a1a;
  border-radius: 50%;
  height: 19px;
  min-width: 8px;
  padding: 0px 5px 0px 5px;
  margin: 7px 2px 0px 0px;
  border: none;
  margin-left: 3px;
  font-size: 10px;
}
.sidebar-add-new-page-group__side-close-new-page:hover {
  background-color: #6d1414;
}
.sidebar-add-new-page-group__side-close-new-page:focus {
  outline: none;
}
.side-add-new-page-button {
  margin-left: 20px;
  width: 238px;
  height: 32px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: relative;
  align-items: center;
  -webkit-box-align: center;
  color: #9daab6;
  border-width: 1px 0px 1px 1px;
  border-style: solid solid solid;
  border-color: transparent transparent transparent;
  border-image: none;
  border-image: initial;
  padding: 6px 24px 7px 16px;
  border-right: 0px;
  text-decoration: none;
  font-size: 14px;
}
.side-add-new-page-button:hover {
  background-color: #e3e6e9;
}
.side-add-new-page-button .plus-icon .plus-icon-img {
  margin-top: 5px;
  height: 15px;
  width: 15px;
}

.sidebar-add-new-group {
  margin-left: 20px;
  font-size: 14px;
  word-break: break-word;
  font-family: Roboto, sans-serif;
  font-weight: 500;
  line-height: 1.5;
  flex: 1 1;
  padding: 9px 20px 10px 16px;
  display: flex;
}
.sidebar-add-new-group__side-group-name-input {
  background-color: white;
  color: #f77d05;
  font-family: "Roboto";
  font-weight: 500;
  font-size: 14px;
  width: 100%;
  line-height: 1.625;
  border: none;
  outline: none;
  padding: 6px 3px 0px 3px;
  margin-right: 3px;
}
.sidebar-add-new-group__side-group-name-input:focus {
  border: none;
}
.sidebar-add-new-group__side-submit-new-group {
  background-color: #26cb7c;
  border-radius: 50%;
  border: none;
  box-shadow: none;
  height: 19px;
  max-width: 9px;
  padding: 0px 4px 0px 4px;
  margin: 7px 2px 0px 0px;
}
.sidebar-add-new-group__side-submit-new-group .submit-logo {
  height: 9px;
  width: 9px;
}
.sidebar-add-new-group__side-submit-new-group:hover {
  background-color: #01924c;
}
.sidebar-add-new-group__side-submit-new-group:focus {
  outline: none;
}
.sidebar-add-new-group__side-close-new-group {
  color: #9daab6;
  padding-top: 0px;
  background-color: #ac1a1a;
  border-radius: 50%;
  height: 19px;
  min-width: 8px;
  padding: 0px 5px 0px 5px;
  margin: 7px 2px 0px 0px;
  border: none;
  margin-left: 3px;
  font-size: 10px;
}
.sidebar-add-new-group__side-close-new-group:hover {
  background-color: #6d1414;
}
.sidebar-add-new-group__side-close-new-group:focus {
  outline: none;
}
.side-folders-button {
  margin-left: 20px;
  width: 238px;
  height: 32px;
  font-size: 14px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: relative;
  align-items: center;
  -webkit-box-align: center;
  color: #9daab6;
  border-width: 1px 0px 1px 1px;
  border-style: solid solid solid;
  border-color: transparent transparent transparent;
  border-image: none;
  border-image: initial;
  padding: 6px 24px 7px 16px;
  border-right: 0px;
  text-decoration: none;
}
.side-folders-button:hover {
  background-color: #e3e6e9;
}
.side-folders-button .folder-icon .folder-icon-img {
  margin-top: 5px;
  height: 15px;
  width: 15px;
}

.open-sidebar-button {
  position: fixed;
  top: 0px;
  left: 0px;
  background-color: white;
  padding: 10px 10px 10px 10px;
  transition: transform 0.3s ease-out;
  z-index: 1000;
}
.open-sidebar-button:focus {
  outline: none;
}
.open-sidebar-button .arrow {
  border: solid black;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 5px;
}
.turn-right {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  transition: transform 0.3s ease-out;
}
.turn-left {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
  transition: transform 0.3s ease-out;
}
.button-stays-in-place {
  transform: translate3d(0px, 0, 0);
}
.button-moves {
  background-color: #f5f7f9;
  transform: translate3d(300px, 0, 0);
}
.sidebar {
  width: 300px;
  min-height: 100vh;
  background-color: #f5f7f9;
  position: fixed;
  top: 0;
  left: 0;
  transition: transform 0.3s ease-out;
  z-index: 1000;
  box-sizing: border-box;
  margin: 0px;
  padding: 0px;
}
.hide {
  transform: translate3d(-300px, 0, 0);
}
.show {
  transform: translate3d(0vw, 0, 0);
  overflow: hidden;
}
.sidebar-header-section {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px 12px 0px 0px;
  margin-left: 20px;
  border-bottom: 1px solid #e6ecf1;
}
.sidebar-header-section__side-logo {
  width: 2rem;
  height: 2rem;
  margin-right: 0px;
}
.sidebar-header-section__side-project-title {
  color: #242a31;
  display: block;
  flex: 1 1;
  margin: 30px;
  font-size: 24px;
  font-family: Content-font, Roboto, sans-serif;
  font-weight: 500;
  line-height: 1.5;
  overflow-wrap: break-word;
}
.sidebar-header-section__side-project-title:hover {
  color: #f77d05;
}
.sidebar-header-section__search-icon {
  padding: 0 5px 0 5px;
}
.sidebar-header-section__search-icon .search-icon-img {
  margin-top: 5px;
  height: 15px;
  width: 15px;
}
.sidebar-header-section__search-icon:hover {
  border-radius: 50%;
  background-color: #e3e6e9;
}
.sidebar-scrollable-section {
  height: 85vh;
  overflow-x: hidden;
  overflow-y: overlay;
}
.sidebar-scrollable-section .sidebar-page-section {
  margin: 30px 0px 0px 20px;
  padding-bottom: 30px;
  border-bottom: 1px solid #e6ecf1;
}
.sidebar-scrollable-section .sidebar-page-section__side-group-list .side-page-groups {
  display: flex;
}
.sidebar-scrollable-section .sidebar-page-section__side-group-list .side-page-groups__side-page-group-name {
  margin-right: 0px;
  display: flex;
  position: relative;
  align-items: center;
  padding: 7px 24px 7px 16px;
  text-decoration: none;
  border: none;
  border-bottom: 1px solid #a8acaf;
  color: black;
  font-size: 14px;
  font-family: Content-font, Roboto, sans-serif;
  font-weight: 600;
  line-height: 1.5;
  width: 85%;
}
.sidebar-scrollable-section .sidebar-page-section__side-group-list .side-page-groups .delete-group-button {
  display: none;
  background-color: transparent;
  border: none;
  font-size: 16px;
  color: #9daab6;
  font-weight: 600;
  cursor: pointer;
  border-bottom: 1px solid #a8acaf;
  padding-right: 25px;
}
.sidebar-scrollable-section .sidebar-page-section__side-group-list .side-page-groups .delete-group-button:hover {
  color: black;
}
.sidebar-scrollable-section .sidebar-page-section__side-group-list .side-page-groups .delete-group-button:focus {
  outline: none;
}
.sidebar-scrollable-section .sidebar-page-section__side-group-list .side-page-groups:hover .delete-group-button {
  display: block;
}
.sidebar-scrollable-section .sidebar-page-section__side-group-list .page-title-list__page-title-content {
  display: flex;
  justify-content: space-between;
}
.sidebar-scrollable-section .sidebar-page-section__side-group-list .page-title-list__page-title-content .page-title {
  margin-right: 0px;
  display: flex;
  position: relative;
  align-items: center;
  -webkit-box-align: center;
  padding: 7px 24px 7px 16px;
  color: #9daab6;
  text-decoration: none;
  font-size: 13px;
  font-family: Content-font, Roboto, sans-serif;
  font-weight: 600;
  line-height: 1.5;
  width: 85%;
  cursor: pointer;
  transition: all 250ms ease-out 0s;
}
.sidebar-scrollable-section .sidebar-page-section__side-group-list .page-title-list__page-title-content .page-title-selected {
  margin-right: 0px;
  display: flex;
  position: relative;
  align-items: center;
  background-color: #ffffff;
  -webkit-box-align: center;
  border: 1px solid black;
  border-right: none;
  padding: 7px 24px 10px 16px;
  text-decoration: none;
  border-color: #e6ecf1;
  color: #f77d05;
  font-size: 13px;
  font-family: Content-font, Roboto, sans-serif;
  font-weight: 600;
  line-height: 1.5;
  width: 85%;
  cursor: pointer;
}
.sidebar-scrollable-section .sidebar-page-section__side-group-list .page-title-list__page-title-content .delete-page-button {
  display: none;
  background-color: transparent;
  border: none;
  font-size: 13px;
  color: #9daab6;
  font-weight: 500;
  padding-right: 25px;
  cursor: pointer;
}
.sidebar-scrollable-section .sidebar-page-section__side-group-list .page-title-list__page-title-content .delete-page-button:hover {
  color: black;
}
.sidebar-scrollable-section .sidebar-page-section__side-group-list .page-title-list__page-title-content .delete-page-button:focus {
  outline: none;
}
.sidebar-scrollable-section .sidebar-page-section__side-group-list .page-title-list__page-title-content .delete-page-button-selected {
  display: none;
  background-color: #ffffff;
  font-size: 13px;
  color: #9daab6;
  font-weight: 500;
  cursor: pointer;
  border: 1px solid #e6ecf1;
  padding: 7px 25px 10px 0px;
  border-left: none;
}
.sidebar-scrollable-section .sidebar-page-section__side-group-list .page-title-list__page-title-content .delete-page-button-selected:hover {
  color: black;
}
.sidebar-scrollable-section .sidebar-page-section__side-group-list .page-title-list__page-title-content .delete-page-button-selected:focus {
  outline: none;
}
.sidebar-scrollable-section .sidebar-page-section__side-group-list .page-title-list__page-title-content:hover {
  background-color: #e3e6e9;
}
.sidebar-scrollable-section .sidebar-page-section__side-group-list .page-title-list__page-title-content:hover .delete-page-button {
  display: block;
}
.sidebar-scrollable-section .sidebar-page-section__side-group-list .page-title-list__page-title-content:hover .delete-page-button-selected {
  display: block;
}
.sidebar-scrollable-section .sidebar-bottom-section {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 30px;
  padding-bottom: 30px;
}
.sidebar-scrollable-section .sidebar-bottom-section__back-to-projects-button {
  margin: 0px 0px 0px 20px;
  color: #ffffff;
  cursor: pointer;
  display: inline-flex;
  line-height: 1em;
  background-color: #3884ff;
  border-color: transparent;
  border-radius: 60px;
  text-decoration: none;
  transition: all 250ms ease-out 0s;
  width: 100px;
  height: 30px;
  font: 400 13.3333px Arial;
  padding: 0px 10px 0px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.sidebar-scrollable-section .sidebar-bottom-section__back-to-projects-button:hover {
  background-color: #0766ff;
}
.sidebar-scrollable-section .sidebar-bottom-section__back-to-projects-button:focus {
  outline: none;
}

.param-container {
  padding: 0px;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  margin-bottom: 5px;
  height: auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.param-container__left-section {
  margin: 3px 10px 0 0;
  display: flex;
  flex-direction: column;
  padding-left: 3px;
  width: 35%;
  min-width: 150px;
}
.param-container .left-section__name-input {
  font-size: 16px;
  font-family: "Roboto", sans-serif;
  color: #74818d;
  font-weight: 500;
  margin: 0 10px 0 5px;
  padding: 0 0 0 0;
  width: 100%;
  border: none transparent;
  outline: none;
  border-bottom: black 1rem;
  line-height: 1.525;
}
.param-container .left-section__name-input:focus {
  border-bottom: blue solid 2px;
  margin-bottom: -2px;
}
.param-container .left-section__name-input:disabled {
  background-color: transparent;
}
.param-container .left-section__option-select {
  margin-top: -5px;
  margin-left: 5px;
  width: 30%;
  min-width: 75px;
  max-width: 75px;
  background-color: transparent;
}
.param-container .left-section__option-select .get-red-required-color__value-container {
  padding: 0 0 0 0;
  margin: 0 0 0 0;
}
.param-container .left-section__option-select .get-red-required-color__value-container .css-b8ldur-Input {
  margin: 0px;
  padding: 0px;
}
.param-container .left-section__option-select .get-red-required-color__value-container--has-value {
  margin-top: 2px;
}
.param-container .left-section__option-select .get-red-required-color__single-value {
  color: red;
  font-size: 10px;
  text-transform: uppercase;
  margin-left: 0;
}
.param-container .left-section__option-select .get-red-required-color__control {
  background-color: transparent;
  border: none;
  outline: none;
  min-height: 0px;
}
.param-container .left-section__option-select .get-red-required-color__control--is-focused {
  border: none;
  outline: none;
  box-shadow: none;
}
.param-container .left-section__option-select .get-red-required-color__control--menu-is-open {
  border: none;
  outline: none;
}
.param-container .left-section__option-select .get-red-required-color__option {
  text-decoration: none;
  font-size: 10px;
  text-transform: uppercase;
  font-weight: 600;
  padding: 5px;
}
.param-container .left-section__option-select .get-red-required-color__option--is-focused {
  color: #3884ff;
}
.param-container .left-section__option-select .get-red-required-color__option--is-selected {
  color: #3884ff;
  background-color: white;
}
.param-container .left-section__option-select .get-red-required-color__dropdown-indicator svg {
  display: none;
  max-height: 0px;
  min-height: 0px;
}
.param-container .left-section__option-select .get-red-required-color__indicator-separator {
  display: none;
}
.param-container .left-section__option-select .get-red-required-color__menu {
  margin: 0px;
  outline: none;
}
.param-container .left-section__option-select .get-grey-optional-color__value-container {
  padding: 0 0 0 0;
  margin: 0 0 0 0;
}
.param-container .left-section__option-select .get-grey-optional-color__value-container .css-b8ldur-Input {
  margin: 0px;
  padding: 0px;
}
.param-container .left-section__option-select .get-grey-optional-color__value-container--has-value {
  margin-top: 2px;
}
.param-container .left-section__option-select .get-grey-optional-color__single-value {
  color: #74818d;
  font-size: 10px;
  text-transform: uppercase;
  margin-left: 0;
}
.param-container .left-section__option-select .get-grey-optional-color__control {
  background-color: transparent;
  border: none;
  outline: none;
  min-height: 0px;
}
.param-container .left-section__option-select .get-grey-optional-color__control--is-focused {
  border: none;
  outline: none;
  box-shadow: none;
}
.param-container .left-section__option-select .get-grey-optional-color__control--menu-is-open {
  border: none;
  outline: none;
}
.param-container .left-section__option-select .get-grey-optional-color__option {
  text-decoration: none;
  font-size: 10px;
  text-transform: uppercase;
  font-weight: 600;
  padding: 5px;
}
.param-container .left-section__option-select .get-grey-optional-color__option--is-focused {
  color: #3884ff;
}
.param-container .left-section__option-select .get-grey-optional-color__option--is-selected {
  color: #3884ff;
  background-color: white;
}
.param-container .left-section__option-select .get-grey-optional-color__dropdown-indicator svg {
  display: none;
  max-height: 0px;
  min-height: 0px;
}
.param-container .left-section__option-select .get-grey-optional-color__indicator-separator {
  display: none;
}
.param-container .left-section__option-select .get-grey-optional-color__menu {
  margin: 0px;
  outline: none;
}
.param-container__right-section {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-left: 5px;
  width: 60%;
  min-width: 150px;
}
.param-container .right-section__type-select {
  min-width: 65px;
  max-width: 65px;
  margin-right: 30px;
}
.param-container .right-section__type-select .type-select__value-container {
  color: black;
  font-size: 85%;
  font-family: "Source Code Pro", Menlo, monospace;
  background-color: #f5f7f9;
  display: inline-block;
  line-height: 1.2;
  margin-top: 0px;
  border-radius: 3px;
  padding: 2px 6px;
}
.param-container .right-section__type-select .type-select__control {
  border: none;
  background-color: #f5f7f9;
  min-height: 28px;
}
.param-container .right-section__type-select .type-select__control--is-focused,
.param-container .right-section__type-select .type-select__control--menu-is-open {
  border: none;
  outline: none;
  box-shadow: none;
}
.param-container .right-section__type-select .type-select__option {
  text-decoration: none;
  font-size: 85%;
  font-weight: 600;
  padding: 5px;
}
.param-container .right-section__type-select .type-select__option--is-focused {
  color: #3884ff;
}
.param-container .right-section__type-select .type-select__option--is-selected {
  color: #3884ff;
  background-color: white;
}
.param-container .right-section__type-select .type-select__dropdown-indicator svg {
  height: 0px;
  color: black;
}
.param-container .right-section__type-select .type-select__indicator {
  display: none;
}
.param-container .right-section__type-select .type-select__indicator-separator {
  display: none;
}
.param-container .right-section__type-select .type-select__menu {
  margin-top: -20px;
  outline: none;
}
.param-container .right-section__describe-input {
  font-family: "Roboto";
  font-weight: 500;
  color: #74818d;
  font-size: 16px;
  width: 80%;
  line-height: 1.625;
  border: none;
  outline: none;
  padding: 0 0 0 0;
  margin: 3px 0px 0 5px;
  min-height: 40px;
  max-height: 100px;
  resize: none;
  overflow: auto;
}
.param-container .right-section__describe-input:focus {
  border: none;
}
.param-container .right-section__describe-input:disabled {
  background-color: transparent;
}
.param-container .button-to-delete {
  display: none;
  position: absolute;
  color: #74818d;
  padding-top: 0px;
  background-color: transparent;
  border: none;
  margin-right: 10px;
  right: 14.5%;
}
.param-container .button-to-delete:hover {
  color: black;
}
.param-container .button-to-delete:focus {
  outline: none;
}
.param-container:hover .button-to-delete {
  display: block;
}

.parameters-block {
  padding-bottom: 0px;
  max-width: 90%;
  margin-left: auto;
  margin-right: auto;
}
.parameters-block__paragraph-title {
  color: #74818d;
  text-transform: capitalize;
  font-size: 16px;
  font-family: Content-font, Roboto, sans-serif;
  font-weight: 400;
  display: block;
  position: relative;
  margin: 0px 0px 0px 0px;
  padding-top: 10px;
  line-height: 1.625;
}
.parameters-block__array-of-params {
  border: 1px solid #e6ecf1;
  padding: 5px 0px 0px 0px;
  margin: 5px 5px 0px 0px;
}

.request-field-container {
  border: 1px solid #e6ecf1;
  border-top: none;
  margin-top: -10px;
  padding-bottom: 10px;
}
.request-field-container__request-selection {
  width: 90%;
  margin: 10px auto 20px auto;
}
.request-field-container__request-selection .selected-request__value-container {
  color: #74818d;
  background-color: white;
  font-size: 16px;
  font-family: "Roboto", Content-font;
  font-weight: 400;
  line-height: 1.2;
  margin: 0px 0px 0px 0px;
  padding: 10px 0px 0px 0px;
  display: inline-block;
}
.request-field-container__request-selection .selected-request__value-container--has-value {
  margin-top: 0px !important;
}
.request-field-container__request-selection .selected-request__single-value {
  line-height: 1.2;
  color: #74818d;
}
.request-field-container__request-selection .selected-request__control {
  border: none;
  background-color: white;
  min-height: 28px;
  max-height: 25px;
}
.request-field-container__request-selection .selected-request__control--is-focused,
.request-field-container__request-selection .selected-request__control--menu-is-open {
  border: none;
  outline: none;
  box-shadow: none;
}
.request-field-container__request-selection .selected-request__option {
  text-decoration: none;
  font-size: 13px;
  font-family: "Roboto", Content-font;
  font-weight: 600;
  padding: 5px;
}
.request-field-container__request-selection .selected-request__option--is-focused {
  color: #3884ff;
}
.request-field-container__request-selection .selected-request__option--is-selected {
  color: #3884ff;
  background-color: white;
}
.request-field-container__request-selection .selected-request__dropdown-indicator svg {
  height: 0px;
  color: black;
}
.request-field-container__request-selection .selected-request__indicator-separator {
  display: none;
}
.request-field-container__request-selection .selected-request__menu {
  margin-top: 0px;
  outline: none;
}

.response-container {
  padding-top: 10px;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  margin-bottom: 15px;
  height: auto;
}
.response-container__top-section {
  display: flex;
  justify-content: space-around;
  width: 100%;
  padding-top: 3px;
}
.response-container .top-section__green-circle {
  margin-top: 4px;
  margin-right: 5px;
  height: 12px;
  width: 12px;
  border-radius: 50%;
  background-color: green;
}
.response-container .top-section__orange-circle {
  margin-top: 4px;
  margin-right: 5px;
  height: 12px;
  width: 12px;
  border-radius: 50%;
  background-color: orange;
  display: inline-block;
}
.response-container .top-section__red-circle {
  margin-top: 4px;
  margin-right: 5px;
  height: 12px;
  width: 12px;
  border-radius: 50%;
  background-color: red;
  display: inline-block;
}
.response-container .top-section__selectedOption {
  width: 100%;
  color: black;
  display: inline-block;
  font-size: 16px;
  font-weight: 600;
  font-family: Content-font, Roboto, sans-serif;
  line-height: 1.2;
  background-color: white;
  border-radius: 3px;
}
.response-container .top-section__button-to-delete {
  display: none;
  position: absolute;
  right: 0;
  line-height: 1;
  color: #74818d;
  background-color: white;
  border: none;
}
.response-container .top-section__button-to-delete:hover {
  color: black;
}
.response-container .top-section__button-to-delete:focus {
  outline: none;
}
.response-container__middle-section {
  display: flex;
  justify-content: space-around;
  width: 100%;
  margin-bottom: 10px;
}
.response-container .middle-section__responeExample-input {
  width: 100%;
  font-family: "Roboto";
  font-weight: 500;
  line-height: 1.625;
  border: none;
  outline: none;
  color: #74818d;
  font-size: 16px;
  padding: 0px;
  margin: 0px;
}
.response-container .middle-section__responeExample-input:focus {
  border: none;
}
.response-container .middle-section__responeExample-input:disabled {
  background-color: transparent;
}
.response-container__bottom-section {
  display: flex;
  max-width: 600px;
}
.response-container .bottom-section__codePane {
  font-family: "Roboto";
  font-weight: 500;
  line-height: 1.625;
  border: none;
  outline: none;
  color: #74818d;
  display: flex;
}
.response-container .bottom-section__codePane .code-editor {
  margin-right: 10px;
  max-width: 71.5vw;
  min-width: 71.5vw;
  background-color: #183055;
  border-radius: 5px;
  color: white;
  border: none;
  outline: none;
  font-family: "Fira code", "Fira Mono", monospace;
  font-size: 10;
}
.response-container .bottom-section__codePane .code-editor:focus {
  outline: none;
  border: none;
}
.response-container .bottom-section__clipboard {
  position: absolute;
  right: 0%;
  margin: 14px 8px 0 0;
  background-color: #183055;
  color: white;
  cursor: pointer;
  padding: 0px 5px 0px 0px;
  align-items: center;
}
.response-container .bottom-section__clipboard .copyButton {
  width: 15px;
  height: 15px;
}
.response-container .bottom-section__clipboard:hover {
  opacity: 0.5;
}
.response-container .bottom-section__clipboard:focus {
  outline: none;
}
.response-container:hover .top-section__button-to-delete {
  display: block;
}

.responses-block {
  padding-bottom: 0px;
  max-width: 90%;
  max-height: 100%;
  margin-left: auto;
  margin-right: auto;
}
.responses-block__array-of-params {
  padding-top: 5px;
  padding-bottom: 0px;
  margin: 5px;
  margin-left: 0px;
  margin-bottom: 0px;
  position: relative;
}

.response-field-container {
  border: 1px solid #e6ecf1;
  border-top: none;
  margin-top: -10px;
}
.response-field-container .select-response {
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10px;
  margin-bottom: 10px;
}
.response-field-container .select-response .selected-response-indi__value-container {
  color: #74818d;
  display: inline-block;
  font-size: 16px;
  font-family: "Roboto", Content-font;
  font-weight: 400;
  line-height: 1.2;
  background-color: white;
  margin: 0px;
  padding: 0;
  margin-top: 0;
}
.response-field-container .select-response .selected-response-indi__value-container--has-value {
  margin-top: 0px;
}
.response-field-container .select-response .selected-response-indi__single-value {
  color: #74818d;
  line-height: 1.2;
}
.response-field-container .select-response .selected-response-indi__control {
  border: none;
  background-color: white;
  min-height: 28px;
  max-height: 25px;
}
.response-field-container .select-response .selected-response-indi__control--is-focused,
.response-field-container .select-response .selected-response-indi__control--menu-is-open {
  border: none;
  outline: none;
  box-shadow: none;
}
.response-field-container .select-response .selected-response-indi__option {
  text-decoration: none;
  font-size: 13px;
  font-family: "Roboto", Content-font;
  font-weight: 600;
  padding: 5px;
}
.response-field-container .select-response .selected-response-indi__option--is-focused {
  color: #3884ff;
}
.response-field-container .select-response .selected-response-indi__option--is-selected {
  color: #3884ff;
  background-color: white;
}
.response-field-container .select-response .selected-response-indi__dropdown-indicator svg {
  height: 0px;
  color: black;
}
.response-field-container .select-response .selected-response-indi__indicator-separator {
  display: none;
}
.response-field-container .select-response .selected-response-indi__menu {
  margin-top: 0px;
  outline: none;
}

.method-container {
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  margin-bottom: 15px;
  height: auto;
  width: 100%;
  margin: auto;
}
.method-container__top-method-section {
  margin: 20px 0 0 0;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}
.method-container .top-method-section__method-select {
  width: 60px;
  margin-right: 20px;
}
.method-container .top-method-section__method-select .get-blue-background-color__value-container {
  font-size: 14px;
  font-family: "Source Code Pro", Menlo, monospace;
  line-height: 1.2;
  background-color: white;
  border-radius: 50px;
  align-items: center;
}
.method-container .top-method-section__method-select .get-blue-background-color__value-container--has-value {
  background-color: #3884ff;
  width: 100%;
  justify-content: center;
  padding-left: 0px;
  padding-top: 1px;
  padding-bottom: 1px;
}
.method-container .top-method-section__method-select .get-blue-background-color__single-value {
  padding-left: 4px;
  color: white;
  font-family: "Source Code Pro", Menlo, monospace;
  font-weight: 600;
}
.method-container .top-method-section__method-select .get-blue-background-color__control {
  padding: 0px;
  border-radius: 50px;
  border: none;
  background-color: white;
  max-height: 25px;
}
.method-container .top-method-section__method-select .get-blue-background-color__control--is-focused,
.method-container .top-method-section__method-select .get-blue-background-color__control--menu-is-open {
  border: none;
  outline: none;
  box-shadow: none;
}
.method-container .top-method-section__method-select .get-blue-background-color__option {
  text-decoration: none;
  font-size: 12px;
  font-weight: 600;
  padding: 5px;
  text-align: center;
}
.method-container .top-method-section__method-select .get-blue-background-color__option--is-focused {
  color: #3884ff;
}
.method-container .top-method-section__method-select .get-blue-background-color__option--is-selected {
  color: #3884ff;
  background-color: white;
}
.method-container .top-method-section__method-select .get-blue-background-color__dropdown-indicator svg {
  height: 0px;
  color: black;
}
.method-container .top-method-section__method-select .get-blue-background-color__indicators {
  display: none;
}
.method-container .top-method-section__method-select .get-blue-background-color__indicator-separator {
  display: none;
}
.method-container .top-method-section__method-select .get-blue-background-color__menu {
  margin-top: 3px;
  outline: none;
}
.method-container .top-method-section__method-select .get-green-background-color__value-container {
  font-size: 14px;
  font-family: "Source Code Pro", Menlo, monospace;
  line-height: 1.2;
  background-color: white;
  border-radius: 50px;
  align-items: center;
}
.method-container .top-method-section__method-select .get-green-background-color__value-container--has-value {
  background-color: #26cb7c;
  width: 100%;
  justify-content: center;
  padding-left: 0px;
  padding-top: 1px;
  padding-bottom: 1px;
}
.method-container .top-method-section__method-select .get-green-background-color__single-value {
  padding-left: 4px;
  color: white;
  font-family: "Source Code Pro", Menlo, monospace;
  font-weight: 600;
}
.method-container .top-method-section__method-select .get-green-background-color__control {
  padding: 0px;
  border-radius: 50px;
  border: none;
  background-color: white;
  max-height: 25px;
}
.method-container .top-method-section__method-select .get-green-background-color__control--is-focused,
.method-container .top-method-section__method-select .get-green-background-color__control--menu-is-open {
  border: none;
  outline: none;
  box-shadow: none;
}
.method-container .top-method-section__method-select .get-green-background-color__option {
  text-decoration: none;
  font-size: 12px;
  font-weight: 600;
  padding: 5px;
  text-align: center;
}
.method-container .top-method-section__method-select .get-green-background-color__option--is-focused {
  color: #3884ff;
}
.method-container .top-method-section__method-select .get-green-background-color__option--is-selected {
  color: #3884ff;
  background-color: white;
}
.method-container .top-method-section__method-select .get-green-background-color__dropdown-indicator svg {
  height: 0px;
  color: black;
}
.method-container .top-method-section__method-select .get-green-background-color__indicators {
  display: none;
}
.method-container .top-method-section__method-select .get-green-background-color__indicator-separator {
  display: none;
}
.method-container .top-method-section__method-select .get-green-background-color__menu {
  margin-top: 3px;
  outline: none;
}
.method-container .top-method-section__method-select .get-orange-background-color__value-container {
  font-size: 14px;
  font-family: "Source Code Pro", Menlo, monospace;
  line-height: 1.2;
  background-color: white;
  border-radius: 50px;
  align-items: center;
}
.method-container .top-method-section__method-select .get-orange-background-color__value-container--has-value {
  background-color: #f77d05;
  width: 100%;
  justify-content: center;
  padding-left: 0px;
  padding-top: 1px;
  padding-bottom: 1px;
}
.method-container .top-method-section__method-select .get-orange-background-color__single-value {
  padding-left: 4px;
  color: white;
  font-family: "Source Code Pro", Menlo, monospace;
  font-weight: 600;
}
.method-container .top-method-section__method-select .get-orange-background-color__control {
  padding: 0px;
  border-radius: 50px;
  border: none;
  background-color: white;
  max-height: 25px;
}
.method-container .top-method-section__method-select .get-orange-background-color__control--is-focused,
.method-container .top-method-section__method-select .get-orange-background-color__control--menu-is-open {
  border: none;
  outline: none;
  box-shadow: none;
}
.method-container .top-method-section__method-select .get-orange-background-color__option {
  text-decoration: none;
  font-size: 12px;
  font-weight: 600;
  padding: 5px;
  text-align: center;
}
.method-container .top-method-section__method-select .get-orange-background-color__option--is-focused {
  color: #3884ff;
}
.method-container .top-method-section__method-select .get-orange-background-color__option--is-selected {
  color: #3884ff;
  background-color: white;
}
.method-container .top-method-section__method-select .get-orange-background-color__dropdown-indicator svg {
  height: 0px;
  color: black;
}
.method-container .top-method-section__method-select .get-orange-background-color__indicators {
  display: none;
}
.method-container .top-method-section__method-select .get-orange-background-color__indicator-separator {
  display: none;
}
.method-container .top-method-section__method-select .get-orange-background-color__menu {
  margin-top: 3px;
  outline: none;
}
.method-container .top-method-section__method-select .get-red-background-color__value-container {
  font-size: 14px;
  font-family: "Source Code Pro", Menlo, monospace;
  line-height: 1.2;
  background-color: white;
  border-radius: 50px;
  align-items: center;
}
.method-container .top-method-section__method-select .get-red-background-color__value-container--has-value {
  background-color: #ff4642;
  width: 100%;
  justify-content: center;
  padding-left: 0px;
  padding-top: 1px;
  padding-bottom: 1px;
}
.method-container .top-method-section__method-select .get-red-background-color__single-value {
  padding-left: 4px;
  color: white;
  font-family: "Source Code Pro", Menlo, monospace;
  font-weight: 600;
}
.method-container .top-method-section__method-select .get-red-background-color__control {
  width: 65px;
  padding: 0px;
  border-radius: 50px;
  border: none;
  background-color: white;
  max-height: 25px;
}
.method-container .top-method-section__method-select .get-red-background-color__control--is-focused,
.method-container .top-method-section__method-select .get-red-background-color__control--menu-is-open {
  border: none;
  outline: none;
  box-shadow: none;
}
.method-container .top-method-section__method-select .get-red-background-color__option {
  text-decoration: none;
  font-size: 12px;
  font-weight: 600;
  padding: 5px;
  text-align: center;
}
.method-container .top-method-section__method-select .get-red-background-color__option--is-focused {
  color: #3884ff;
}
.method-container .top-method-section__method-select .get-red-background-color__option--is-selected {
  color: #3884ff;
  background-color: white;
}
.method-container .top-method-section__method-select .get-red-background-color__dropdown-indicator svg {
  height: 0px;
  color: black;
}
.method-container .top-method-section__method-select .get-red-background-color__indicators {
  display: none;
}
.method-container .top-method-section__method-select .get-red-background-color__indicator-separator {
  display: none;
}
.method-container .top-method-section__method-select .get-red-background-color__menu {
  margin-top: 3px;
  outline: none;
}
.method-container .top-method-section__method-select .get-lightBlue-background-color__value-container {
  font-size: 14px;
  font-family: "Source Code Pro", Menlo, monospace;
  line-height: 1.2;
  background-color: white;
  border-radius: 50px;
  align-items: center;
}
.method-container .top-method-section__method-select .get-lightBlue-background-color__value-container--has-value {
  background-color: #03d1eb;
  width: 100%;
  justify-content: center;
  padding-left: 0px;
  padding-top: 1px;
  padding-bottom: 1px;
}
.method-container .top-method-section__method-select .get-lightBlue-background-color__single-value {
  padding-left: 4px;
  color: white;
  font-family: "Source Code Pro", Menlo, monospace;
  font-weight: 600;
}
.method-container .top-method-section__method-select .get-lightBlue-background-color__control {
  padding: 0px;
  border-radius: 50px;
  border: none;
  background-color: white;
  max-height: 25px;
}
.method-container .top-method-section__method-select .get-lightBlue-background-color__control--is-focused,
.method-container .top-method-section__method-select .get-lightBlue-background-color__control--menu-is-open {
  border: none;
  outline: none;
  box-shadow: none;
}
.method-container .top-method-section__method-select .get-lightBlue-background-color__option {
  text-decoration: none;
  font-size: 12px;
  font-weight: 600;
  padding: 5px;
  text-align: center;
}
.method-container .top-method-section__method-select .get-lightBlue-background-color__option--is-focused {
  color: #3884ff;
}
.method-container .top-method-section__method-select .get-lightBlue-background-color__option--is-selected {
  color: #3884ff;
  background-color: white;
}
.method-container .top-method-section__method-select .get-lightBlue-background-color__dropdown-indicator svg {
  height: 0px;
  color: black;
}
.method-container .top-method-section__method-select .get-lightBlue-background-color__indicators {
  display: none;
}
.method-container .top-method-section__method-select .get-lightBlue-background-color__indicator-separator {
  display: none;
}
.method-container .top-method-section__method-select .get-lightBlue-background-color__menu {
  margin-top: 3px;
  outline: none;
}
.method-container .top-method-section__method-select .get-purple-background-color__value-container {
  font-size: 14px;
  font-family: "Source Code Pro", Menlo, monospace;
  line-height: 1.2;
  background-color: white;
  border-radius: 50px;
  align-items: center;
}
.method-container .top-method-section__method-select .get-purple-background-color__value-container--has-value {
  background-color: #a44eed;
  width: 100%;
  justify-content: center;
  padding-left: 0px;
  padding-top: 1px;
  padding-bottom: 1px;
}
.method-container .top-method-section__method-select .get-purple-background-color__single-value {
  padding-left: 4px;
  color: white;
  font-family: "Source Code Pro", Menlo, monospace;
  font-weight: 600;
}
.method-container .top-method-section__method-select .get-purple-background-color__control {
  padding: 0px;
  border-radius: 50px;
  border: none;
  background-color: white;
  max-height: 25px;
}
.method-container .top-method-section__method-select .get-purple-background-color__control--is-focused,
.method-container .top-method-section__method-select .get-purple-background-color__control--menu-is-open {
  border: none;
  outline: none;
  box-shadow: none;
}
.method-container .top-method-section__method-select .get-purple-background-color__option {
  text-decoration: none;
  font-size: 12px;
  font-weight: 600;
  padding: 5px;
  text-align: center;
}
.method-container .top-method-section__method-select .get-purple-background-color__option--is-focused {
  color: #3884ff;
}
.method-container .top-method-section__method-select .get-purple-background-color__option--is-selected {
  color: #3884ff;
  background-color: white;
}
.method-container .top-method-section__method-select .get-purple-background-color__dropdown-indicator svg {
  height: 0px;
  color: black;
}
.method-container .top-method-section__method-select .get-purple-background-color__indicators {
  display: none;
}
.method-container .top-method-section__method-select .get-purple-background-color__indicator-separator {
  display: none;
}
.method-container .top-method-section__method-select .get-purple-background-color__menu {
  margin-top: 3px;
  outline: none;
}
.method-container .top-method-section__method-select .get-yellow-background-color__value-container {
  font-size: 14px;
  font-family: "Source Code Pro", Menlo, monospace;
  line-height: 1.2;
  background-color: white;
  border-radius: 50px;
  align-items: center;
}
.method-container .top-method-section__method-select .get-yellow-background-color__value-container--has-value {
  background-color: #ffd139;
  width: 100%;
  justify-content: center;
  padding-left: 0px;
  padding-top: 1px;
  padding-bottom: 1px;
}
.method-container .top-method-section__method-select .get-yellow-background-color__single-value {
  padding-left: 4px;
  color: white;
  font-family: "Source Code Pro", Menlo, monospace;
  font-weight: 600;
}
.method-container .top-method-section__method-select .get-yellow-background-color__control {
  width: 70px;
  padding: 0px;
  border-radius: 50px;
  border: none;
  background-color: white;
  max-height: 25px;
}
.method-container .top-method-section__method-select .get-yellow-background-color__control--is-focused,
.method-container .top-method-section__method-select .get-yellow-background-color__control--menu-is-open {
  border: none;
  outline: none;
  box-shadow: none;
}
.method-container .top-method-section__method-select .get-yellow-background-color__option {
  text-decoration: none;
  font-size: 12px;
  font-weight: 600;
  padding: 5px;
  text-align: center;
}
.method-container .top-method-section__method-select .get-yellow-background-color__option--is-focused {
  color: #3884ff;
}
.method-container .top-method-section__method-select .get-yellow-background-color__option--is-selected {
  color: #3884ff;
  background-color: white;
}
.method-container .top-method-section__method-select .get-yellow-background-color__dropdown-indicator svg {
  height: 0px;
  color: black;
}
.method-container .top-method-section__method-select .get-yellow-background-color__indicators {
  display: none;
}
.method-container .top-method-section__method-select .get-yellow-background-color__indicator-separator {
  display: none;
}
.method-container .top-method-section__method-select .get-yellow-background-color__menu {
  margin-top: 3px;
  outline: none;
}
.method-container .top-method-section__title-input {
  width: 80%;
  font-family: "Roboto";
  font-weight: 700;
  line-height: 1.625;
  border: none;
  outline: none;
  color: black;
  font-size: 18px;
  padding: 0px;
  margin: 0px;
}
.method-container .top-method-section__title-input:focus {
  border: none;
}
.method-container .top-method-section__title-input::-webkit-input-placeholder {
  font-family: "Roboto";
  font-weight: 500;
  line-height: 1.625;
  border: none;
  outline: none;
  color: #74818d;
  font-size: 16px;
}
.method-container .top-method-section__title-input:-ms-input-placeholder {
  font-family: "Roboto";
  font-weight: 500;
  line-height: 1.625;
  border: none;
  outline: none;
  color: #74818d;
  font-size: 16px;
}
.method-container .top-method-section__title-input::-ms-input-placeholder {
  font-family: "Roboto";
  font-weight: 500;
  line-height: 1.625;
  border: none;
  outline: none;
  color: #74818d;
  font-size: 16px;
}
.method-container .top-method-section__title-input::placeholder {
  font-family: "Roboto";
  font-weight: 500;
  line-height: 1.625;
  border: none;
  outline: none;
  color: #74818d;
  font-size: 16px;
}
.method-container .top-method-section__title-input:disabled {
  background-color: transparent;
}
.method-container .top-method-section__method-to-delete-button {
  display: none;
  position: absolute;
  right: 10%;
  font-size: 18px;
  color: #74818d;
  background-color: white;
  border: none;
  padding-top: 0px;
}
.method-container .top-method-section__method-to-delete-button:hover {
  color: black;
}
.method-container .top-method-section__method-to-delete-button:focus {
  outline: none;
}
.method-container__middle-section {
  width: 100%;
  display: flex;
  margin: 20px 0 0 0;
}
.method-container .middle-section__apiURL-input {
  border-radius: 5px;
  background-color: #f5f7f9;
  width: 100%;
  font-family: "Roboto";
  font-weight: 600;
  line-height: 1.625;
  border: none;
  outline: none;
  color: #242a31;
  font-size: 16px;
  padding: 10px;
  margin: 0px;
}
.method-container .middle-section__apiURL-input:focus {
  border: none;
}
.method-container .middle-section__apiURL-input::-webkit-input-placeholder {
  font-family: "Roboto";
  font-weight: 500;
  line-height: 1.625;
  border: none;
  outline: none;
  color: #74818d;
  font-size: 16px;
}
.method-container .middle-section__apiURL-input:-ms-input-placeholder {
  font-family: "Roboto";
  font-weight: 500;
  line-height: 1.625;
  border: none;
  outline: none;
  color: #74818d;
  font-size: 16px;
}
.method-container .middle-section__apiURL-input::-ms-input-placeholder {
  font-family: "Roboto";
  font-weight: 500;
  line-height: 1.625;
  border: none;
  outline: none;
  color: #74818d;
  font-size: 16px;
}
.method-container .middle-section__apiURL-input::placeholder {
  font-family: "Roboto";
  font-weight: 500;
  line-height: 1.625;
  border: none;
  outline: none;
  color: #74818d;
  font-size: 16px;
}
.method-container .middle-section__apiURL-input:disabled {
  background-color: transparent;
}
.method-container__bottom-section {
  display: flex;
  margin: 20px 0 0 0;
}
.method-container .bottom-section__describe-input {
  width: 100%;
  font-family: "Roboto";
  font-weight: 500;
  line-height: 1.625;
  border: none;
  outline: none;
  color: #74818d;
  font-size: 16px;
  padding: 0px;
  margin: 0px;
}
.method-container .bottom-section__describe-input:focus {
  border: none;
}
.method-container .bottom-section__describe-input:disabled {
  background-color: transparent;
}
.method-container .react-tabs {
  border-color: #e6ecf1;
  font-size: 16px;
  font-family: Content-font, Roboto, sans-serif;
  font-weight: 400;
  color: #74818d;
  margin-bottom: 20px;
}
.method-container .react-tabs__tab-list {
  border-bottom: 1px solid #e6ecf1;
  margin-top: 10px;
}
.method-container .react-tabs__tab {
  padding: 8px 8px;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  align-items: center;
  background-color: #f5f7f9;
}
.method-container .react-tabs__tab--hover {
  color: black;
}
.method-container .react-tabs__tab--selected {
  outline: none;
  background-color: white;
  color: black;
  border-color: #e6ecf1 #e6ecf1 #ffffff;
}
.method-container:hover .top-method-section__method-to-delete-button {
  display: block;
}

.page-container {
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  border: none;
}
.page-container .page-top-section {
  display: flex;
  flex-wrap: wrap;
  align-items: baseline;
  padding: 40px 0px;
  border-bottom: 2px solid #e6ecf1;
  margin-bottom: 30px;
}
.page-container .page-top-section__save-button {
  position: absolute;
  top: 45px;
  right: 10%;
  align-items: baseline;
  color: #ffffff;
  cursor: pointer;
  height: 30px;
  display: inline-flex;
  align-items: center;
  line-height: 1em;
  background-color: #26cb7c;
  border-width: 1px;
  border-style: solid;
  outline: none;
  padding: 0px 12px;
  border-color: transparent;
  border-radius: 60px;
  transition: all 250ms ease-out 0s;
}
.page-container .page-top-section__save-button .tick-logo {
  height: 13px;
  width: 13px;
  margin: 0px 8px 0px 0px;
}
.page-container .page-top-section__save-button .save-text {
  font-weight: 500;
  padding-top: 0px 10px;
}
.page-container .page-top-section__save-button:hover {
  background-color: #01924c;
}
.page-container .page-top-section__title-input {
  width: 100%;
  font-family: Content-font, Roboto, sans-serif;
  font-weight: 500;
  line-height: 1.5;
  border: none;
  outline: none;
  color: #242a31;
  font-size: 32px;
  padding: 0px;
  margin: 0px;
}
.page-container .page-top-section__title-input:focus {
  border: none;
}
.page-container .page-top-section__title-input:disabled {
  background-color: transparent;
}
.page-container .page-top-section__page-description {
  width: 100%;
  font-family: "Roboto";
  font-weight: 400;
  line-height: 1.625;
  border: none;
  outline: none;
  color: #74818d;
  font-size: 16px;
  margin: 8px 0px 0px;
  min-height: 19px;
}
.page-container .page-top-section__page-description:focus {
  border: none;
}
.page-container .page-top-section__page-description:disabled {
  background-color: transparent;
}
.page-container__page-middle-section .border-between-methods {
  padding-top: 10px;
  padding-bottom: 20px;
  border-bottom: 1px solid #e6ecf1;
}
.page-container__page-bottom-section {
  padding-bottom: 10px;
  border: none;
}
.page-container__page-bottom-section .method-to-add-button {
  position: absolute;
  left: 5%;
  margin-top: 0px;
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 0px;
  padding-bottom: 1px;
  margin-bottom: 20px;
  font-size: 13px;
  font-weight: 600;
  align-items: center;
  color: #74818d;
  background-color: white;
  border: solid 2px #74818d;
  border-radius: 50%;
}
.page-container__page-bottom-section .method-to-add-button:hover {
  color: black;
  background-color: #74818d;
}
.page-container__page-bottom-section .method-to-add-button:focus {
  outline: none;
}

.login-container {
    height: 100vh;
    width: calc(100vw - 2rem);
    background-color: #263c5c;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 1rem;
}
.login-container__login-modal {
    width: 100%;
    max-width: 600px;
    height: 50%;
    background-color: white;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
}
.login-container .login-form {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    min-height: 300px;
}
.login-container .login-form .input-format {
    padding: 10px 20px;
    font-weight: 600;
    color: black;
    font-size: 16px;
    width: 60%;
    border: none;
    border-bottom: 1.5px solid #b8bcc0;
    margin: 0px auto 20px auto;
}
.login-container .login-form .input-format:focus {
    outline: none;
}
.login-container .login-form .input-format::-webkit-input-placeholder {
    color: #74818d;
}
.login-container .login-form .input-format:-ms-input-placeholder {
    color: #74818d;
}
.login-container .login-form .input-format::-ms-input-placeholder {
    color: #74818d;
}
.login-container .login-form .input-format::placeholder {
    color: #74818d;
}
.login-container .login-modal__sign-up {
    font-family: "Roboto";
    font-weight: 600;
    color: black;
    font-size: 20px;
    text-align: center;
    width: 60%;
    line-height: 1.625;
    outline: none;
    padding: 70px 0;
    margin: 0px auto 0 auto;
}
.login-container .login-modal__fields-to-fill-warning {
    text-align: center;
    color: #cc3d3d;
    width: 50%;
    margin: 0px auto 0 auto;
}
.login-container .login-modal__login-buttons {
    width: 60%;
    margin: 20px auto 50px auto;
    text-decoration: none;
    color: #ffffff;
    font-weight: 600;
    cursor: pointer;
    height: 30px;
    line-height: 1em;
    background-color: #263c5c;
    border-width: 1px;
    outline: none;
    padding: 0px 16px;
    border-color: transparent;
    border-radius: 60px;
    transition: all 250ms ease-out 0s;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2px 2px 2px 2px;
}
.login-container .login-modal__login-buttons:hover {
    background-color: #1b2c44;
}
.login-container .login-modal__loading {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}
@media screen and (max-height: 860px) {
    .login-container__login-modal {
        height: 60%;
    }
    .login-container__login-modal__sign-up {
        padding: 55px 0;
    }
}
@media screen and (max-height: 760px) {
    .login-container__login-modal {
        height: 70%;
    }
    .login-container__login-modal__sign-up {
        padding: 40px 0;
    }
}
@media screen and (max-width: 576px) {
    .login-container .login-modal__sign-up {
        padding: 40px 0;
    }
}

.unauthorized-container {
  height: 100vh;
  width: 100vw;
  background-color: #263c5c;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.unauthorized-container__informative-text {
  margin: 100px 0 0 0;
  color: white;
  font-size: 18px;
}
.unauthorized-container__informative-text-2 {
  margin: 0 0 20px 0;
  color: white;
  font-size: 18px;
}
.unauthorized-container__get-back-to-login {
  width: 30%;
  margin: 0 auto 50px auto;
  text-decoration: none;
  color: #ffffff;
  font-weight: 600;
  font-size: 18px;
  cursor: pointer;
  height: 30px;
  line-height: 1em;
  background-color: #263c5c;
  border-width: 1px;
  outline: none;
  padding: 0px 16px;
  border-color: transparent;
  border-radius: 60px;
  transition: all 250ms ease-out 0s;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2px 2px 2px 2px;
}
.unauthorized-container__get-back-to-login:hover {
  background-color: #1b2c44;
  color: white;
  text-decoration: none;
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
        "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

* {
    box-sizing: unset;
}

img {
    vertical-align: unset;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.error-container__oneline-text {
    color: rgb(204, 61, 61);
    font-size: 16px;
    margin-left: 7rem;
}

