.page-container {
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  border: none;
}
.page-container .page-top-section {
  display: flex;
  flex-wrap: wrap;
  align-items: baseline;
  padding: 40px 0px;
  border-bottom: 2px solid #e6ecf1;
  margin-bottom: 30px;
}
.page-container .page-top-section__save-button {
  position: absolute;
  top: 45px;
  right: 10%;
  align-items: baseline;
  color: #ffffff;
  cursor: pointer;
  height: 30px;
  display: inline-flex;
  align-items: center;
  line-height: 1em;
  background-color: #26cb7c;
  border-width: 1px;
  border-style: solid;
  outline: none;
  padding: 0px 12px;
  border-color: transparent;
  border-radius: 60px;
  transition: all 250ms ease-out 0s;
}
.page-container .page-top-section__save-button .tick-logo {
  height: 13px;
  width: 13px;
  margin: 0px 8px 0px 0px;
}
.page-container .page-top-section__save-button .save-text {
  font-weight: 500;
  padding-top: 0px 10px;
}
.page-container .page-top-section__save-button:hover {
  background-color: #01924c;
}
.page-container .page-top-section__title-input {
  width: 100%;
  font-family: Content-font, Roboto, sans-serif;
  font-weight: 500;
  line-height: 1.5;
  border: none;
  outline: none;
  color: #242a31;
  font-size: 32px;
  padding: 0px;
  margin: 0px;
}
.page-container .page-top-section__title-input:focus {
  border: none;
}
.page-container .page-top-section__title-input:disabled {
  background-color: transparent;
}
.page-container .page-top-section__page-description {
  width: 100%;
  font-family: "Roboto";
  font-weight: 400;
  line-height: 1.625;
  border: none;
  outline: none;
  color: #74818d;
  font-size: 16px;
  margin: 8px 0px 0px;
  min-height: 19px;
}
.page-container .page-top-section__page-description:focus {
  border: none;
}
.page-container .page-top-section__page-description:disabled {
  background-color: transparent;
}
.page-container__page-middle-section .border-between-methods {
  padding-top: 10px;
  padding-bottom: 20px;
  border-bottom: 1px solid #e6ecf1;
}
.page-container__page-bottom-section {
  padding-bottom: 10px;
  border: none;
}
.page-container__page-bottom-section .method-to-add-button {
  position: absolute;
  left: 5%;
  margin-top: 0px;
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 0px;
  padding-bottom: 1px;
  margin-bottom: 20px;
  font-size: 13px;
  font-weight: 600;
  align-items: center;
  color: #74818d;
  background-color: white;
  border: solid 2px #74818d;
  border-radius: 50%;
}
.page-container__page-bottom-section .method-to-add-button:hover {
  color: black;
  background-color: #74818d;
}
.page-container__page-bottom-section .method-to-add-button:focus {
  outline: none;
}
