.sidebar-add-new-group {
  margin-left: 20px;
  font-size: 14px;
  word-break: break-word;
  font-family: Roboto, sans-serif;
  font-weight: 500;
  line-height: 1.5;
  flex: 1 1 0%;
  padding: 9px 20px 10px 16px;
  display: flex;
}
.sidebar-add-new-group__side-group-name-input {
  background-color: white;
  color: #f77d05;
  font-family: "Roboto";
  font-weight: 500;
  font-size: 14px;
  width: 100%;
  line-height: 1.625;
  border: none;
  outline: none;
  padding: 6px 3px 0px 3px;
  margin-right: 3px;
}
.sidebar-add-new-group__side-group-name-input:focus {
  border: none;
}
.sidebar-add-new-group__side-submit-new-group {
  background-color: #26cb7c;
  border-radius: 50%;
  border: none;
  box-shadow: none;
  height: 19px;
  max-width: 9px;
  padding: 0px 4px 0px 4px;
  margin: 7px 2px 0px 0px;
}
.sidebar-add-new-group__side-submit-new-group .submit-logo {
  height: 9px;
  width: 9px;
}
.sidebar-add-new-group__side-submit-new-group:hover {
  background-color: #01924c;
}
.sidebar-add-new-group__side-submit-new-group:focus {
  outline: none;
}
.sidebar-add-new-group__side-close-new-group {
  color: #9daab6;
  padding-top: 0px;
  background-color: #ac1a1a;
  border-radius: 50%;
  height: 19px;
  min-width: 8px;
  padding: 0px 5px 0px 5px;
  margin: 7px 2px 0px 0px;
  border: none;
  margin-left: 3px;
  font-size: 10px;
}
.sidebar-add-new-group__side-close-new-group:hover {
  background-color: #6d1414;
}
.sidebar-add-new-group__side-close-new-group:focus {
  outline: none;
}
.side-folders-button {
  margin-left: 20px;
  width: 238px;
  height: 32px;
  font-size: 14px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: relative;
  align-items: center;
  -webkit-box-align: center;
  color: #9daab6;
  border-width: 1px 0px 1px 1px;
  border-style: solid solid solid;
  border-color: transparent transparent transparent;
  border-image: initial;
  padding: 6px 24px 7px 16px;
  border-right: 0px;
  text-decoration: none;
}
.side-folders-button:hover {
  background-color: #e3e6e9;
}
.side-folders-button .folder-icon .folder-icon-img {
  margin-top: 5px;
  height: 15px;
  width: 15px;
}
