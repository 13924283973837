.response-field-container {
  border: 1px solid #e6ecf1;
  border-top: none;
  margin-top: -10px;
}
.response-field-container .select-response {
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10px;
  margin-bottom: 10px;
}
.response-field-container .select-response .selected-response-indi__value-container {
  color: #74818d;
  display: inline-block;
  font-size: 16px;
  font-family: "Roboto", Content-font;
  font-weight: 400;
  line-height: 1.2;
  background-color: white;
  margin: 0px;
  padding: 0;
  margin-top: 0;
}
.response-field-container .select-response .selected-response-indi__value-container--has-value {
  margin-top: 0px;
}
.response-field-container .select-response .selected-response-indi__single-value {
  color: #74818d;
  line-height: 1.2;
}
.response-field-container .select-response .selected-response-indi__control {
  border: none;
  background-color: white;
  min-height: 28px;
  max-height: 25px;
}
.response-field-container .select-response .selected-response-indi__control--is-focused,
.response-field-container .select-response .selected-response-indi__control--menu-is-open {
  border: none;
  outline: none;
  box-shadow: none;
}
.response-field-container .select-response .selected-response-indi__option {
  text-decoration: none;
  font-size: 13px;
  font-family: "Roboto", Content-font;
  font-weight: 600;
  padding: 5px;
}
.response-field-container .select-response .selected-response-indi__option--is-focused {
  color: #3884ff;
}
.response-field-container .select-response .selected-response-indi__option--is-selected {
  color: #3884ff;
  background-color: white;
}
.response-field-container .select-response .selected-response-indi__dropdown-indicator svg {
  height: 0px;
  color: black;
}
.response-field-container .select-response .selected-response-indi__indicator-separator {
  display: none;
}
.response-field-container .select-response .selected-response-indi__menu {
  margin-top: 0px;
  outline: none;
}
