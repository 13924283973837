.method-container {
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  margin-bottom: 15px;
  height: auto;
  width: 100%;
  margin: auto;
}
.method-container__top-method-section {
  margin: 20px 0 0 0;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}
.method-container .top-method-section__method-select {
  width: 60px;
  margin-right: 20px;
}
.method-container .top-method-section__method-select .get-blue-background-color__value-container {
  font-size: 14px;
  font-family: "Source Code Pro", Menlo, monospace;
  line-height: 1.2;
  background-color: white;
  border-radius: 50px;
  align-items: center;
}
.method-container .top-method-section__method-select .get-blue-background-color__value-container--has-value {
  background-color: #3884ff;
  width: 100%;
  justify-content: center;
  padding-left: 0px;
  padding-top: 1px;
  padding-bottom: 1px;
}
.method-container .top-method-section__method-select .get-blue-background-color__single-value {
  padding-left: 4px;
  color: white;
  font-family: "Source Code Pro", Menlo, monospace;
  font-weight: 600;
}
.method-container .top-method-section__method-select .get-blue-background-color__control {
  padding: 0px;
  border-radius: 50px;
  border: none;
  background-color: white;
  max-height: 25px;
}
.method-container .top-method-section__method-select .get-blue-background-color__control--is-focused,
.method-container .top-method-section__method-select .get-blue-background-color__control--menu-is-open {
  border: none;
  outline: none;
  box-shadow: none;
}
.method-container .top-method-section__method-select .get-blue-background-color__option {
  text-decoration: none;
  font-size: 12px;
  font-weight: 600;
  padding: 5px;
  text-align: center;
}
.method-container .top-method-section__method-select .get-blue-background-color__option--is-focused {
  color: #3884ff;
}
.method-container .top-method-section__method-select .get-blue-background-color__option--is-selected {
  color: #3884ff;
  background-color: white;
}
.method-container .top-method-section__method-select .get-blue-background-color__dropdown-indicator svg {
  height: 0px;
  color: black;
}
.method-container .top-method-section__method-select .get-blue-background-color__indicators {
  display: none;
}
.method-container .top-method-section__method-select .get-blue-background-color__indicator-separator {
  display: none;
}
.method-container .top-method-section__method-select .get-blue-background-color__menu {
  margin-top: 3px;
  outline: none;
}
.method-container .top-method-section__method-select .get-green-background-color__value-container {
  font-size: 14px;
  font-family: "Source Code Pro", Menlo, monospace;
  line-height: 1.2;
  background-color: white;
  border-radius: 50px;
  align-items: center;
}
.method-container .top-method-section__method-select .get-green-background-color__value-container--has-value {
  background-color: #26cb7c;
  width: 100%;
  justify-content: center;
  padding-left: 0px;
  padding-top: 1px;
  padding-bottom: 1px;
}
.method-container .top-method-section__method-select .get-green-background-color__single-value {
  padding-left: 4px;
  color: white;
  font-family: "Source Code Pro", Menlo, monospace;
  font-weight: 600;
}
.method-container .top-method-section__method-select .get-green-background-color__control {
  padding: 0px;
  border-radius: 50px;
  border: none;
  background-color: white;
  max-height: 25px;
}
.method-container .top-method-section__method-select .get-green-background-color__control--is-focused,
.method-container .top-method-section__method-select .get-green-background-color__control--menu-is-open {
  border: none;
  outline: none;
  box-shadow: none;
}
.method-container .top-method-section__method-select .get-green-background-color__option {
  text-decoration: none;
  font-size: 12px;
  font-weight: 600;
  padding: 5px;
  text-align: center;
}
.method-container .top-method-section__method-select .get-green-background-color__option--is-focused {
  color: #3884ff;
}
.method-container .top-method-section__method-select .get-green-background-color__option--is-selected {
  color: #3884ff;
  background-color: white;
}
.method-container .top-method-section__method-select .get-green-background-color__dropdown-indicator svg {
  height: 0px;
  color: black;
}
.method-container .top-method-section__method-select .get-green-background-color__indicators {
  display: none;
}
.method-container .top-method-section__method-select .get-green-background-color__indicator-separator {
  display: none;
}
.method-container .top-method-section__method-select .get-green-background-color__menu {
  margin-top: 3px;
  outline: none;
}
.method-container .top-method-section__method-select .get-orange-background-color__value-container {
  font-size: 14px;
  font-family: "Source Code Pro", Menlo, monospace;
  line-height: 1.2;
  background-color: white;
  border-radius: 50px;
  align-items: center;
}
.method-container .top-method-section__method-select .get-orange-background-color__value-container--has-value {
  background-color: #f77d05;
  width: 100%;
  justify-content: center;
  padding-left: 0px;
  padding-top: 1px;
  padding-bottom: 1px;
}
.method-container .top-method-section__method-select .get-orange-background-color__single-value {
  padding-left: 4px;
  color: white;
  font-family: "Source Code Pro", Menlo, monospace;
  font-weight: 600;
}
.method-container .top-method-section__method-select .get-orange-background-color__control {
  padding: 0px;
  border-radius: 50px;
  border: none;
  background-color: white;
  max-height: 25px;
}
.method-container .top-method-section__method-select .get-orange-background-color__control--is-focused,
.method-container .top-method-section__method-select .get-orange-background-color__control--menu-is-open {
  border: none;
  outline: none;
  box-shadow: none;
}
.method-container .top-method-section__method-select .get-orange-background-color__option {
  text-decoration: none;
  font-size: 12px;
  font-weight: 600;
  padding: 5px;
  text-align: center;
}
.method-container .top-method-section__method-select .get-orange-background-color__option--is-focused {
  color: #3884ff;
}
.method-container .top-method-section__method-select .get-orange-background-color__option--is-selected {
  color: #3884ff;
  background-color: white;
}
.method-container .top-method-section__method-select .get-orange-background-color__dropdown-indicator svg {
  height: 0px;
  color: black;
}
.method-container .top-method-section__method-select .get-orange-background-color__indicators {
  display: none;
}
.method-container .top-method-section__method-select .get-orange-background-color__indicator-separator {
  display: none;
}
.method-container .top-method-section__method-select .get-orange-background-color__menu {
  margin-top: 3px;
  outline: none;
}
.method-container .top-method-section__method-select .get-red-background-color__value-container {
  font-size: 14px;
  font-family: "Source Code Pro", Menlo, monospace;
  line-height: 1.2;
  background-color: white;
  border-radius: 50px;
  align-items: center;
}
.method-container .top-method-section__method-select .get-red-background-color__value-container--has-value {
  background-color: #ff4642;
  width: 100%;
  justify-content: center;
  padding-left: 0px;
  padding-top: 1px;
  padding-bottom: 1px;
}
.method-container .top-method-section__method-select .get-red-background-color__single-value {
  padding-left: 4px;
  color: white;
  font-family: "Source Code Pro", Menlo, monospace;
  font-weight: 600;
}
.method-container .top-method-section__method-select .get-red-background-color__control {
  width: 65px;
  padding: 0px;
  border-radius: 50px;
  border: none;
  background-color: white;
  max-height: 25px;
}
.method-container .top-method-section__method-select .get-red-background-color__control--is-focused,
.method-container .top-method-section__method-select .get-red-background-color__control--menu-is-open {
  border: none;
  outline: none;
  box-shadow: none;
}
.method-container .top-method-section__method-select .get-red-background-color__option {
  text-decoration: none;
  font-size: 12px;
  font-weight: 600;
  padding: 5px;
  text-align: center;
}
.method-container .top-method-section__method-select .get-red-background-color__option--is-focused {
  color: #3884ff;
}
.method-container .top-method-section__method-select .get-red-background-color__option--is-selected {
  color: #3884ff;
  background-color: white;
}
.method-container .top-method-section__method-select .get-red-background-color__dropdown-indicator svg {
  height: 0px;
  color: black;
}
.method-container .top-method-section__method-select .get-red-background-color__indicators {
  display: none;
}
.method-container .top-method-section__method-select .get-red-background-color__indicator-separator {
  display: none;
}
.method-container .top-method-section__method-select .get-red-background-color__menu {
  margin-top: 3px;
  outline: none;
}
.method-container .top-method-section__method-select .get-lightBlue-background-color__value-container {
  font-size: 14px;
  font-family: "Source Code Pro", Menlo, monospace;
  line-height: 1.2;
  background-color: white;
  border-radius: 50px;
  align-items: center;
}
.method-container .top-method-section__method-select .get-lightBlue-background-color__value-container--has-value {
  background-color: #03d1eb;
  width: 100%;
  justify-content: center;
  padding-left: 0px;
  padding-top: 1px;
  padding-bottom: 1px;
}
.method-container .top-method-section__method-select .get-lightBlue-background-color__single-value {
  padding-left: 4px;
  color: white;
  font-family: "Source Code Pro", Menlo, monospace;
  font-weight: 600;
}
.method-container .top-method-section__method-select .get-lightBlue-background-color__control {
  padding: 0px;
  border-radius: 50px;
  border: none;
  background-color: white;
  max-height: 25px;
}
.method-container .top-method-section__method-select .get-lightBlue-background-color__control--is-focused,
.method-container .top-method-section__method-select .get-lightBlue-background-color__control--menu-is-open {
  border: none;
  outline: none;
  box-shadow: none;
}
.method-container .top-method-section__method-select .get-lightBlue-background-color__option {
  text-decoration: none;
  font-size: 12px;
  font-weight: 600;
  padding: 5px;
  text-align: center;
}
.method-container .top-method-section__method-select .get-lightBlue-background-color__option--is-focused {
  color: #3884ff;
}
.method-container .top-method-section__method-select .get-lightBlue-background-color__option--is-selected {
  color: #3884ff;
  background-color: white;
}
.method-container .top-method-section__method-select .get-lightBlue-background-color__dropdown-indicator svg {
  height: 0px;
  color: black;
}
.method-container .top-method-section__method-select .get-lightBlue-background-color__indicators {
  display: none;
}
.method-container .top-method-section__method-select .get-lightBlue-background-color__indicator-separator {
  display: none;
}
.method-container .top-method-section__method-select .get-lightBlue-background-color__menu {
  margin-top: 3px;
  outline: none;
}
.method-container .top-method-section__method-select .get-purple-background-color__value-container {
  font-size: 14px;
  font-family: "Source Code Pro", Menlo, monospace;
  line-height: 1.2;
  background-color: white;
  border-radius: 50px;
  align-items: center;
}
.method-container .top-method-section__method-select .get-purple-background-color__value-container--has-value {
  background-color: #a44eed;
  width: 100%;
  justify-content: center;
  padding-left: 0px;
  padding-top: 1px;
  padding-bottom: 1px;
}
.method-container .top-method-section__method-select .get-purple-background-color__single-value {
  padding-left: 4px;
  color: white;
  font-family: "Source Code Pro", Menlo, monospace;
  font-weight: 600;
}
.method-container .top-method-section__method-select .get-purple-background-color__control {
  padding: 0px;
  border-radius: 50px;
  border: none;
  background-color: white;
  max-height: 25px;
}
.method-container .top-method-section__method-select .get-purple-background-color__control--is-focused,
.method-container .top-method-section__method-select .get-purple-background-color__control--menu-is-open {
  border: none;
  outline: none;
  box-shadow: none;
}
.method-container .top-method-section__method-select .get-purple-background-color__option {
  text-decoration: none;
  font-size: 12px;
  font-weight: 600;
  padding: 5px;
  text-align: center;
}
.method-container .top-method-section__method-select .get-purple-background-color__option--is-focused {
  color: #3884ff;
}
.method-container .top-method-section__method-select .get-purple-background-color__option--is-selected {
  color: #3884ff;
  background-color: white;
}
.method-container .top-method-section__method-select .get-purple-background-color__dropdown-indicator svg {
  height: 0px;
  color: black;
}
.method-container .top-method-section__method-select .get-purple-background-color__indicators {
  display: none;
}
.method-container .top-method-section__method-select .get-purple-background-color__indicator-separator {
  display: none;
}
.method-container .top-method-section__method-select .get-purple-background-color__menu {
  margin-top: 3px;
  outline: none;
}
.method-container .top-method-section__method-select .get-yellow-background-color__value-container {
  font-size: 14px;
  font-family: "Source Code Pro", Menlo, monospace;
  line-height: 1.2;
  background-color: white;
  border-radius: 50px;
  align-items: center;
}
.method-container .top-method-section__method-select .get-yellow-background-color__value-container--has-value {
  background-color: #ffd139;
  width: 100%;
  justify-content: center;
  padding-left: 0px;
  padding-top: 1px;
  padding-bottom: 1px;
}
.method-container .top-method-section__method-select .get-yellow-background-color__single-value {
  padding-left: 4px;
  color: white;
  font-family: "Source Code Pro", Menlo, monospace;
  font-weight: 600;
}
.method-container .top-method-section__method-select .get-yellow-background-color__control {
  width: 70px;
  padding: 0px;
  border-radius: 50px;
  border: none;
  background-color: white;
  max-height: 25px;
}
.method-container .top-method-section__method-select .get-yellow-background-color__control--is-focused,
.method-container .top-method-section__method-select .get-yellow-background-color__control--menu-is-open {
  border: none;
  outline: none;
  box-shadow: none;
}
.method-container .top-method-section__method-select .get-yellow-background-color__option {
  text-decoration: none;
  font-size: 12px;
  font-weight: 600;
  padding: 5px;
  text-align: center;
}
.method-container .top-method-section__method-select .get-yellow-background-color__option--is-focused {
  color: #3884ff;
}
.method-container .top-method-section__method-select .get-yellow-background-color__option--is-selected {
  color: #3884ff;
  background-color: white;
}
.method-container .top-method-section__method-select .get-yellow-background-color__dropdown-indicator svg {
  height: 0px;
  color: black;
}
.method-container .top-method-section__method-select .get-yellow-background-color__indicators {
  display: none;
}
.method-container .top-method-section__method-select .get-yellow-background-color__indicator-separator {
  display: none;
}
.method-container .top-method-section__method-select .get-yellow-background-color__menu {
  margin-top: 3px;
  outline: none;
}
.method-container .top-method-section__title-input {
  width: 80%;
  font-family: "Roboto";
  font-weight: 700;
  line-height: 1.625;
  border: none;
  outline: none;
  color: black;
  font-size: 18px;
  padding: 0px;
  margin: 0px;
}
.method-container .top-method-section__title-input:focus {
  border: none;
}
.method-container .top-method-section__title-input::placeholder {
  font-family: "Roboto";
  font-weight: 500;
  line-height: 1.625;
  border: none;
  outline: none;
  color: #74818d;
  font-size: 16px;
}
.method-container .top-method-section__title-input:disabled {
  background-color: transparent;
}
.method-container .top-method-section__method-to-delete-button {
  display: none;
  position: absolute;
  right: 10%;
  font-size: 18px;
  color: #74818d;
  background-color: white;
  border: none;
  padding-top: 0px;
}
.method-container .top-method-section__method-to-delete-button:hover {
  color: black;
}
.method-container .top-method-section__method-to-delete-button:focus {
  outline: none;
}
.method-container__middle-section {
  width: 100%;
  display: flex;
  margin: 20px 0 0 0;
}
.method-container .middle-section__apiURL-input {
  border-radius: 5px;
  background-color: #f5f7f9;
  width: 100%;
  font-family: "Roboto";
  font-weight: 600;
  line-height: 1.625;
  border: none;
  outline: none;
  color: #242a31;
  font-size: 16px;
  padding: 10px;
  margin: 0px;
}
.method-container .middle-section__apiURL-input:focus {
  border: none;
}
.method-container .middle-section__apiURL-input::placeholder {
  font-family: "Roboto";
  font-weight: 500;
  line-height: 1.625;
  border: none;
  outline: none;
  color: #74818d;
  font-size: 16px;
}
.method-container .middle-section__apiURL-input:disabled {
  background-color: transparent;
}
.method-container__bottom-section {
  display: flex;
  margin: 20px 0 0 0;
}
.method-container .bottom-section__describe-input {
  width: 100%;
  font-family: "Roboto";
  font-weight: 500;
  line-height: 1.625;
  border: none;
  outline: none;
  color: #74818d;
  font-size: 16px;
  padding: 0px;
  margin: 0px;
}
.method-container .bottom-section__describe-input:focus {
  border: none;
}
.method-container .bottom-section__describe-input:disabled {
  background-color: transparent;
}
.method-container .react-tabs {
  border-color: #e6ecf1;
  font-size: 16px;
  font-family: Content-font, Roboto, sans-serif;
  font-weight: 400;
  color: #74818d;
  margin-bottom: 20px;
}
.method-container .react-tabs__tab-list {
  border-bottom: 1px solid #e6ecf1;
  margin-top: 10px;
}
.method-container .react-tabs__tab {
  padding: 8px 8px;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  align-items: center;
  background-color: #f5f7f9;
}
.method-container .react-tabs__tab--hover {
  color: black;
}
.method-container .react-tabs__tab--selected {
  outline: none;
  background-color: white;
  color: black;
  border-color: #e6ecf1 #e6ecf1 #ffffff;
}
.method-container:hover .top-method-section__method-to-delete-button {
  display: block;
}
