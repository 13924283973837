.parameters-block {
  padding-bottom: 0px;
  max-width: 90%;
  margin-left: auto;
  margin-right: auto;
}
.parameters-block__paragraph-title {
  color: #74818d;
  text-transform: capitalize;
  font-size: 16px;
  font-family: Content-font, Roboto, sans-serif;
  font-weight: 400;
  display: block;
  position: relative;
  margin: 0px 0px 0px 0px;
  padding-top: 10px;
  line-height: 1.625;
}
.parameters-block__array-of-params {
  border: 1px solid #e6ecf1;
  padding: 5px 0px 0px 0px;
  margin: 5px 5px 0px 0px;
}
