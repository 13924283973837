.responses-block {
  padding-bottom: 0px;
  max-width: 90%;
  max-height: 100%;
  margin-left: auto;
  margin-right: auto;
}
.responses-block__array-of-params {
  padding-top: 5px;
  padding-bottom: 0px;
  margin: 5px;
  margin-left: 0px;
  margin-bottom: 0px;
  position: relative;
}
